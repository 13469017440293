import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  Fragment,
} from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  useToast,
  Skeleton,
  Badge,
  Switch,
  FormLabel,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { format, parseISO } from 'date-fns';
import Draft, { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import queryString from 'query-string';
import * as Yup from 'yup';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import { User } from '~/modules/accessControl/@types/user';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import TextareaChakra from '~/shared/components/InputChakra/Textarea';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import SectionHeader from '~/shared/components/SectionHeader';
import Tab from '~/shared/components/Tab';
import Table, { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import getPerformanceReviewNotifications from '~/utils/getPerformanceReviewNotifications';
import getValidationErrors from '~/utils/getValidationErrors';

import RowDimension from '../../../dashboard/components/RadioBox';
import {
  Dimension,
  NoteType,
  Note,
  Score,
  ScoreTable,
  Concept,
  EvaluationCustom,
  Evaluator,
} from '../../@types/performanceReview';

interface SelectDimension {
  id: number;
  name: string;
}

interface Params {
  id?: string;
}

interface NoteForm {
  notes: Note[];
}

interface PerformanceProps {
  dimensions: Dimension[];
  notes_types: NoteType[];
  team: User[];
  scores: Score[];
}

const ShowPerformanceReview: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenPublishEvaluation,
    onOpen: onOpenPublishEvaluation,
    onClose: onClosePublishEvaluation,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenPublish,
    onOpen: onOpenPublish,
    onClose: onClosePublish,
  } = useDisclosure();
  const { id } = useParams<Params>();
  const addToast = useToast();
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const wk = queryParams?.work_deal;
  const ep = queryParams?.evaluation_period;
  const onlyReceived = queryParams?.recebidas;

  const goPerformanceReview = useMemo(() => {
    let url = `/relatorios/avaliacao-desempenho${
      wk ? `?work_deal=${wk}` : ''
    }&${ep ? `evaluation_period=${ep}` : ''}`;

    url += onlyReceived ? `&recebidas=1` : ``;

    return url;
  }, [wk, ep, onlyReceived]);

  const noteNotifications = getPerformanceReviewNotifications();

  const can = useCan();

  const canListPerformanceReviews = can('list-performance-reviews');
  const canCreatePerformanceReviews = can('create-performance-reviews');

  const [loading, setLoading] = useState(true);
  const [loadingSaveScore, setLoadingSaveScore] = useState(false);
  const [loadingOption, setLoadingOption] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPublishEvaluation, setLoadingPublishEvaluation] = useState(
    false,
  );
  const [toogleNote, setToogleNote] = useState(false);
  const [publish, setPublish] = useState(false);

  const [totalScore, setTotalScore] = useState(0);
  const [countConcepts, setCountConcepts] = useState(0);

  const [defaultNoteIndex, setDefaultNoteIndex] = useState(0);
  /* const [performance, setPerformance] = useState<PerformanceProps>(); */
  // const EditorStateDescription = EditorState.createEmpty();
  // const EditorStateEvaluation = EditorState.createEmpty();
  // const EditorStateRecommendation = EditorState.createEmpty();

  const [editorReference, setEditorReference] = useState<any>();

  const { EditorState: EditorStateDescription } = Draft;
  const { EditorState: EditorStateEvaluation } = Draft;
  const { EditorState: EditorStateRecommendation } = Draft;

  const [
    evaluationPerformance,
    setEvaluationPerformance,
  ] = useState<EvaluationCustom>({} as EvaluationCustom);
  const [performDimensions, setPerformDimensions] = useState<Dimension[]>([]);
  const [conceptDimensions, setConceptDimensions] = useState<Concept[]>([]);
  const [typesNotes, setTypesNotes] = useState<NoteType[]>([]);
  const [ratedUser, setRatedUser] = useState<User>();
  const [evaluatorUser, setEvaluatorUser] = useState<Evaluator[]>([]);
  const [liderEvaluator, setLiderEvaluator] = useState<Evaluator>();
  const [performScore, setPerformScore] = useState<Score[]>([]);
  const [tableScore, setTableScore] = useState<ScoreTable[]>([]);
  const [performTeam, setPerformTeam] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [selectCredentialNote, setSelectCredentialNote] = useState<{
    id: number;
    count?: number;
  }>();
  const [selectedCountTab, setSelectCountTab] = useState(0);
  const [deleteNote, setDeleteNote] = useState<{
    id: number;
    count: number;
  }>();

  const [
    selectedDimensionConcepts,
    setSelectedDimensionConcepts,
  ] = useState<SelectDimension | null>(null);
  const [editorDescription, setEditorDescription] = useState<EditorState[]>([
    EditorStateDescription.createEmpty(),
  ]);
  const [editorEvaluation, setEditorEvaluation] = useState<EditorState[]>([
    EditorStateEvaluation.createEmpty(),
  ]);
  const [editorRecommendation, setEditorRecommendation] = useState<
    EditorState[]
  >([EditorStateRecommendation.createEmpty()]);

  const [radioGroups, setRadioGroups] = useState<
    Array<{
      rootProps: any;
      radioProps: any;
    }>
  >([]);

  const [notes, setNotes] = useState<Note[]>([]);
  const [countNotes, setCountNotes] = useState(0);

  const currentDate = new Date();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formRefNote = useRef<FormHandles>(null);
  const formRefNotePublish = useRef<FormHandles>(null);
  const formRefFilter = useRef<FormHandles>(null);
  const formRefEditor = useRef<FormHandles>(null);

  const countDimensionConcepts = useCallback(
    (dimension) => {
      let countConc: number;
      countConc = 0;

      if (id) {
        countConc += dimension.length || 0;
      } else {
        dimension.forEach((dim: any) => {
          countConc += dim.dimension_concepts?.length || 0;
        });
      }
      setCountConcepts(countConc);
    },
    [id],
  );

  const calculateDimensionConcepts = useCallback(
    (concepts: Concept[], sum?: number) => {
      const sumScore: number = sum ?? 0;

      const scoreReduce = concepts?.reduce((accumulator, conc) => {
        // console.log(accumulator);
        // console.log(conc);
        return accumulator + Number(conc.score_id);
      }, sumScore);
      // console.log(sumScore, scoreReduce);
      // sumScore += scoreReduce;
      // console.log('score', scoreReduce);
      return scoreReduce;
    },
    [],
  );

  /* const allowShowPerformanceReview = useCallback(
    (ratedUserId): boolean => {
      return ratedUserId === user.id || canListPerformanceReviews;
    },
    [canListPerformanceReviews, user.id],
  ); */

  /* const calculateDimensionConcepts = useCallback(
    (updatedConcept: Concept, sum?: number) => {
      const sumScore: number = sum ?? 0;

      const scoreReduce = pe.reduce((accumulator, conc) => {
        // console.log(accumulator, conc);
        return accumulator + Number(conc.score?.note);
      }, sumScore);
      // console.log(sumScore, scoreReduce);
      // sumScore += scoreReduce;
      // console.log(scoreReduce);
      return scoreReduce;
    },
    [],
  ); */

  const handleCalculateDimensionConcept = useCallback(
    (conceptId: number, score: string) => {
      // let sumScore: number;
      // sumScore = 0;

      // console.log(conceptDimensions);
      const dimension_concepts: Concept[] = id
        ? evaluationPerformance.dimension_concepts
        : conceptDimensions;

      const updtConcepts = dimension_concepts?.map((updtConc) => {
        return updtConc.id === conceptId
          ? {
              ...updtConc,

              score_id: score,
            }
          : {
              ...updtConc,
              score_id: '0',
            };
      });
      if (id) {
        setEvaluationPerformance((state) => ({
          ...state,
          dimension_concepts: updtConcepts,
        }));
      } else {
        setPerformDimensions((state) =>
          state.map((perfDim) => {
            return {
              ...perfDim,
              dimension_concepts: updtConcepts.filter(
                (conc) => conc.pr_dimension_id === perfDim.id,
              ),
            };
          }),
        );
      }
      setTotalScore(calculateDimensionConcepts(updtConcepts));
    },
    [calculateDimensionConcepts, evaluationPerformance, conceptDimensions, id],
  );
  const calculateTotalScore = useCallback(
    (conceptId: number, score: string) => {
      let sumScore: number;
      sumScore = 0;

      performDimensions.forEach((dim) => {
        const { dimension_concepts } = dim;

        if (dimension_concepts?.length) {
          const updatedConcept = dimension_concepts.map((dimenConc) => {
            return dimenConc.id === conceptId
              ? {
                  ...dimenConc,
                  score_id: score,
                }
              : dimenConc;
          });
          sumScore += calculateDimensionConcepts(updatedConcept, sumScore);
        }
      });
      setTotalScore(sumScore);
    },
    [calculateDimensionConcepts, performDimensions],
  );

  const showPerformanceReview = useCallback(
    async (concepts: Concept[]) => {
      setLoading(true);

      await api
        .get(`reports/performance-reviews/${id}`)
        .then((response) => {
          const {
            notes: notesEditor,
            dimension_concepts,
            rated_user,
            evaluator_users,
          } = response.data;

          // console.log(evaluator_users);

          // console.log(concepts);

          let dimensionConceptsScore: Concept[] = [];

          if (dimension_concepts.length) {
            dimensionConceptsScore = dimension_concepts.map(
              (dimenConc: any) => {
                // console.log('value: ', dimenConc.score.id);
                return {
                  ...dimenConc,
                  score_id: dimenConc.score.id,
                };
              },
            );

            // console.log(conceptDimensions);

            setConceptDimensions(
              concepts.map((concDim) => {
                // console.log(concDim.score_id);
                return {
                  ...concDim,
                  score_id: dimensionConceptsScore.find((dimenConc: any) => {
                    // console.log(dimenConc.id, concDim.id);
                    return Number(dimenConc.id) === concDim.id;
                  })?.score_id,
                };
              }),
            );

            const total = calculateDimensionConcepts(dimensionConceptsScore);
            setTotalScore(total);
          }

          setEvaluationPerformance({
            ...response.data,
            dimension_concepts: dimensionConceptsScore,
            created_at: format(
              parseISO(response.data.created_at),
              'dd/MM/yyyy',
            ),
            updated_at: format(
              parseISO(response.data.updated_at),
              'dd/MM/yyyy',
            ),
          });

          setLiderEvaluator(
            evaluator_users.find((evUser: any) => {
              return evUser.is_leader;
            }),
          );
          setEvaluatorUser(evaluator_users);
          setRatedUser(rated_user);
          setNotes(notesEditor);
        })
        .finally(() => setLoading(false));
    },
    [id, calculateDimensionConcepts],
  );

  const column =
    liderEvaluator?.id === Number(user.id) || ratedUser?.id === Number(user.id)
      ? [
          {
            title: 'Desempenho muito abaixo do esperado',
          },
          {
            title: 'Desempenho pouco abaixo do esperado',
          },
          {
            title: 'Desempenho esperado',
          },
          {
            title: 'Desempenho pouco acima do esperado',
          },
          {
            title: 'Desempenho muito acima do esperado',
          },
        ]
      : [];

  const tableTitles = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Conceito',
      },
      {
        title: 'Adicione uma anotação',
      },
    ];
  }, []);

  const getOptionsPerformanceReview = useCallback(async (): Promise<
    Concept[]
  > => {
    setLoadingOption(true);

    const concDimensions: Concept[] = [];

    await api
      .get<PerformanceProps>('/reports/performance-reviews/options')
      .then((response) => {
        const { dimensions, notes_types, team, scores } = response.data;

        setPerformDimensions(dimensions);

        dimensions.forEach((dim) => {
          dim.dimension_concepts.forEach((dimConc) => {
            concDimensions.push(dimConc);
          });
        });

        // console.log(concDimensions);

        setConceptDimensions(concDimensions);

        setTypesNotes(notes_types);
        setPerformScore(scores);
        setTableScore(
          scores.map((scor) => {
            return {
              ...scor,
              title: scor.title,
            };
          }),
        );
        setPerformTeam(team);
      })
      .finally(() => setLoadingOption(false));

    // console.log('caiundo');
    return concDimensions;
  }, []);

  useEffect(() => {
    const promiseConcepts = getOptionsPerformanceReview();

    promiseConcepts
      .then((concepts) => {
        if (id) showPerformanceReview(concepts);
      })
      .catch(() => {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            'Não foi possível obter os dados necessários para a avaliação',
          isClosable: true,
        });
      });
  }, [getOptionsPerformanceReview, showPerformanceReview, id, addToast]);

  const handleEmployeeSelect = useCallback(
    (userId: string) => {
      const selectedEmployee = performTeam.find(
        (employee) => employee.id === Number(userId),
      );
      if (selectedEmployee) {
        setSelectedUser({
          id: selectedEmployee.id,
          name: selectedEmployee.name,
          email: selectedEmployee.email,
          responsibility: selectedEmployee.responsibility,
        });
      }
    },
    [performTeam],
  );

  const findDefaultNoteIndex = useCallback(
    (dimensionId: number) => {
      const noteIndex = notes?.findIndex(
        (nt) => nt.pr_dimension_concept_id === dimensionId,
      );

      setDefaultNoteIndex(noteIndex > 0 ? noteIndex : 0);
    },
    [notes],
  );

  const handleSelectedDimensionConcept = useCallback(
    (conceptId: number) => {
      const countNts = notes?.filter(
        (nts) => nts.pr_dimension_concept_id === conceptId,
      ).length;

      const firstConceptNote = notes.find(
        (nt) => nt.pr_dimension_concept_id === conceptId,
      );

      const concept = conceptDimensions.find((conc) => conc.id === conceptId);
      if (!concept) return;

      if (countNts && firstConceptNote) {
        setSelectedDimensionConcepts({
          id: concept.id,
          name: concept.name,
        });

        // Verificar se a nota que abrir é nova, para marca-lá como lida
        // if(verifyNewNote(firstConceptNote.id))

        setSelectCredentialNote({ id: firstConceptNote.id });
      } else {
        setCountNotes((state) => state + 1);
        setSelectedDimensionConcepts({
          id: concept.id,
          name: concept.name,
        });

        const count = countNotes + 1;
        const defaultNote: Note = {
          id: 0,
          pr_dimension_concept_id: conceptId,
          count: countNotes + 1,
          fact_description: '',
          recommendation: '',
          evaluation_consequence: '',
          status_id: 25,
        };
        setNotes((state) => [...state, defaultNote]);

        setSelectCredentialNote({ id: 0, count });

        // setando o ultimo index do estado
        setDefaultNoteIndex(notes.length - 1);
      }

      setToogleNote(true);
    },
    [conceptDimensions, notes, countNotes],
  );

  const handleAddAnnotation = useCallback(
    (dimensionId) => {
      const countNts = notes.filter(
        (nts) => nts.pr_dimension_concept_id === dimensionId,
      ).length;

      setCountNotes((state) => state + 1);

      setNotes((state) => [
        ...state,
        {
          id: 0,
          count: countNotes + 1,
          pr_dimension_concept_id: dimensionId,
          fact_description: '',
          recommendation: '',
          evaluation_consequence: '',
          status_id: 25,
        },
      ]);
    },
    [notes, countNotes],
  );

  const handleRemoveAnnotation = useCallback(() => {
    if (deleteNote && deleteNote.id > 0) {
      api.delete(`/reports/performance-reviews/notes/${deleteNote.id}`);
    }

    setNotes((state) =>
      state.filter((note) => {
        if (deleteNote && deleteNote.id > 0) {
          return note.id !== deleteNote.id;
        }
        return note.count !== deleteNote?.count;
      }),
    );
  }, [deleteNote]);

  const handleTab = useCallback(
    (idx: number) => {
      const countNts = notes.filter(
        (nts) => nts.pr_dimension_concept_id === selectedDimensionConcepts?.id,
      ).length;

      if (countNts) {
        const contentDescription = htmlToDraft(
          notes[idx]?.fact_description ?? '',
        );
        const contentEvaluation = htmlToDraft(
          notes[idx].evaluation_consequence ?? '',
        );
        const contentRecommendation = htmlToDraft(
          notes[idx].recommendation ?? '',
        );

        if (!editorDescription.find((edDesc, index) => index === idx)) {
          setEditorDescription((state) => [
            ...state,
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                contentDescription.contentBlocks,
              ),
            ),
          ]);
        }

        setEditorEvaluation((state) => [
          ...state,
          EditorState.createWithContent(
            ContentState.createFromBlockArray(contentEvaluation.contentBlocks),
          ),
        ]);
        setEditorRecommendation((state) => [
          ...state,
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              contentRecommendation.contentBlocks,
            ),
          ),
        ]);
      } else {
        setEditorDescription([EditorState.createEmpty()]);
        setEditorEvaluation([EditorState.createEmpty()]);
        setEditorRecommendation([EditorState.createEmpty()]);
      }
    },
    [notes, selectedDimensionConcepts, editorDescription],
  );

  const convertedTextHtml = useMemo((): string[] => {
    const currentContent = editorDescription.map((editor) => {
      const currentContent2 = editor.getCurrentContent();

      if (!currentContent2.hasText()) return '';

      const rawContentState = convertToRaw(currentContent2);
      return draftToHtml(rawContentState);
    });

    return currentContent;
  }, [editorDescription]);

  const handleCreate = useCallback(
    async (data) => {
      // console.log(data);
      setLoadingSaveScore(true);

      try {
        formRefEditor.current?.setErrors({});
        const { scores } = data;
        if (scores.length) {
          data.scores = scores.flat().filter((scr: any) => !!scr.pr_score_id);
        }
        if (!id) {
          const validation = Yup.object().shape({
            rated_user_id: Yup.string().required('Escolha um funcionario'),
          });

          await validation.validate(data, {
            abortEarly: false,
          });
        } else {
          data.evaluation_id = id;
        }

        if (notes.length) {
          data.notes = notes
            .filter((ntPublish) => !ntPublish.published_at)
            .map((nt) => {
              return nt.id > 0
                ? nt
                : {
                    pr_dimension_concept_id: nt.pr_dimension_concept_id,
                    pr_note_type_id: nt.pr_note_type_id,
                    pr_evaluation_id: nt.pr_evaluation_id,
                    fact_description: nt.fact_description,
                    evaluation_consequence: nt.evaluation_consequence,
                    recommendation: nt.recommendation,
                  };
            });
        }

        await api.post('reports/performance-reviews', data);

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Avaliação Salva!',
          description: 'A Avaliação foi salva com sucesso.',
          isClosable: true,
        });

        history.push({
          pathname: `/relatorios/avaliacao-desempenho`,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na atualização',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao cadastrar sua atividade',
          isClosable: true,
        });
      } finally {
        setLoadingSaveScore(false);
      }
    },
    [addToast, id, notes, history],
  );

  async function handlePublishEvaluation(): Promise<void> {
    setLoadingPublishEvaluation(true);

    await api
      .patch(`reports/performance-reviews/${id}/publish`)
      .then(() => {
        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Avaliação Publicada!',
          description:
            'A avaliação a partir de agora não poderá ser modificada e o avaliado terá ciência de todas as informações publicadas',
          isClosable: true,
        });
        history.goBack();
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Não foi possível publicar a avaliação',
          description:
            err.response?.data?.error ||
            'Ocorreu um erro ao publicar esta avaliação',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingPublishEvaluation(false);
      });
  }

  async function handleSubmitNote(data: NoteForm): Promise<void> {
    const { notes: notesForm } = data;

    try {
      formRefNote.current?.setErrors({});

      const validation = Yup.object().shape({
        notes: Yup.array().of(
          Yup.object().shape({
            pr_note_type_id: Yup.string().required(
              'É necessário selecionar um tipo',
            ),
          }),
        ),
      });

      await validation.validate(data, {
        abortEarly: false,
      });

      setToogleNote(!toogleNote);

      const existentNotes: Note[] = [];
      const newNotes: Note[] = [];

      notesForm.forEach((nf) => {
        if (nf.id && nf.id > 0) {
          existentNotes.push(nf);
        } else {
          newNotes.push(nf);
        }
      });

      setNotes((state) =>
        state.map((nt) => {
          const updtNote =
            nt.id > 0
              ? existentNotes.find(
                  (existNote) => Number(existNote.id) === nt.id,
                )
              : newNotes.find((newNote) => Number(newNote.count) === nt.count);

          return updtNote
            ? {
                ...nt,
                pr_dimension_concept_id: Number(
                  updtNote.pr_dimension_concept_id,
                ),
                pr_note_type_id: updtNote.pr_note_type_id,
                fact_description: updtNote.fact_description,
                evaluation_consequence: updtNote.evaluation_consequence,
                recommendation: updtNote.recommendation,
              }
            : nt;
        }),
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        const noteError = notesForm.find((ntForm) => !ntForm.pr_note_type_id);
        formRefNote.current?.setErrors(errors);
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'warning',
          title: 'Toda anotação precisa de um tipo',
          description: `A ${
            noteError && noteError?.id > 0
              ? `Anotação - ${noteError?.id}`
              : `Nova Anotação - ${noteError?.count}`
          } não possui um tipo selecionado`,
        });
        return;
      }

      addToast({
        position: 'top-right',
        isClosable: true,
        status: 'error',
        title: 'Erro ao adicionar/atualizar anotação',
      });
    }
  }

  const handleCreateNote = useCallback(
    async (dataForm: { notes: Note[] }) => {
      setLoadingButton(true);
      // console.log(dataForm, selectCredentialNote);
      formRefNotePublish.current?.setErrors({});

      const { notes: noteUpdate } = dataForm;
      let updtNote: Note | null;
      // updtNote = {} as Note;

      let ntUpdtIndex: number;

      if (selectCredentialNote && selectCredentialNote.id > 0) {
        updtNote =
          noteUpdate.find((nt: any) => {
            return Number(nt.id) === selectCredentialNote.id;
          }) || null;

        ntUpdtIndex = noteUpdate.findIndex(
          (ntIndex) => Number(ntIndex.id) === selectCredentialNote.id,
        );
      } else {
        updtNote =
          noteUpdate.find((nt: any) => {
            return Number(nt.count) === selectCredentialNote?.count;
          }) || null;

        ntUpdtIndex = noteUpdate.findIndex(
          (ntIndex) => Number(ntIndex.count) === selectCredentialNote?.count,
        );
      }
      // console.log(updtNote);
      try {
        const schema = Yup.object().shape({
          pr_note_type_id: Yup.string().required(
            'É necessário selecionar um tipo',
          ),
        });

        await schema.validate(updtNote, {
          abortEarly: false,
        });

        if (updtNote) updtNote.pr_evaluation_id = Number(id);
        if (selectCredentialNote && selectCredentialNote.id > 0) {
          const response = await api.put<Note>(
            `/reports/performance-reviews/notes/${selectCredentialNote.id}`,
            updtNote,
            {
              params: { publish: Number(publish) },
            },
          );

          const { data } = response;

          setNotes((state) =>
            state.map((nt) => {
              return nt.id === Number(selectCredentialNote.id) ? data : nt;
            }),
          );
        } else {
          const response = await api.post<Note>(
            `/reports/performance-reviews/notes`,
            updtNote,
            {
              params: { publish: Number(publish) },
            },
          );

          const { data } = response;

          setSelectCredentialNote((state) => ({
            ...state,
            id: data.id,
          }));

          setNotes((state) =>
            state.map((nt) => {
              return nt.count === Number(selectCredentialNote?.count)
                ? data
                : nt;
            }),
          );
        }

        addToast({
          position: 'top-right',
          status: 'success',
          title: publish ? 'Anotação publicada!' : 'Anotação Salva',
          description: publish
            ? 'Anotação foi publicada com sucesso.'
            : 'Anotação foi salva com sucesso.',
          isClosable: true,
        });
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const arr = JSON.parse(
            `{"notes[${ntUpdtIndex}].pr_note_type_id": "Selecione um tipo de anotação"}`,
          );

          formRefNotePublish.current?.setErrors(arr);
          return;
        }

        addToast({
          position: 'top-right',
          status: 'warning',
          isClosable: true,
          title:
            err?.response?.data?.error || publish
              ? 'Erro ao publicar!'
              : 'Erro ao Salvar!',
        });
      } finally {
        setLoadingButton(false);
      }
    },
    [addToast, publish, selectCredentialNote, id],
  );

  const handleNotePublish = useCallback(
    async (noteId) => {
      try {
        const publishedNote = notes.find((nt) => nt.id === noteId);

        await api.patch(`/reports/performance-reviews/notes/${noteId}/publish`);

        setNotes((state) =>
          state.map((nt) => {
            return {
              ...nt,
              published_at: nt.published_at,
            };
          }),
        );

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Anotação publicada!',
          description: 'Anotação foi publicada com sucesso.',
          isClosable: true,
        });
      } catch (error) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Erro ao publicar anotação',
          description: 'Erro ao publicar anotação',
        });
      }
    },
    [addToast, notes],
  );

  const handleFindLeaderEvaluator = useCallback(
    (evaluatorUsers: Evaluator[]) => {
      return evaluatorUsers.find((ev) => ev.is_leader);
    },
    [],
  );

  function verifyNewNote(noteId: number): boolean {
    return !!noteNotifications.find((nt) => noteId === nt.data?.note_id);
  }

  const handleShowSavedScore = useCallback((): boolean => {
    return (
      liderEvaluator?.id === Number(user.id) ||
      (!!evaluationPerformance.published_at &&
        ratedUser?.id === Number(user.id))
    );
  }, [evaluationPerformance, liderEvaluator, ratedUser, user]);

  return (
    <>
      <Form ref={formRef} onSubmit={handleCreate}>
        <Box>
          <SectionHeader
            title="Avaliação de Desempenho"
            pagename="Avaliação bimestral"
            goBackLink={goPerformanceReview}
            loading={loading}
          >
            {canCreatePerformanceReviews &&
              Number(user.id) === liderEvaluator?.id &&
              !evaluationPerformance?.published_at && (
                <Flex>
                  <Button
                    colorScheme="green"
                    onClick={() => formRef.current?.submitForm()}
                    mr={4}
                    isLoading={loadingSaveScore}
                  >
                    Salvar Pontuação
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={onOpenPublishEvaluation}
                    isLoading={loadingPublishEvaluation}
                  >
                    Publicar
                  </Button>
                </Flex>
              )}
          </SectionHeader>
          {!loading ? (
            <Box width="calc(100% - 25%)">
              <Flex alignItems="center" mb={4}>
                <Text fontSize={['md', 'xl']} mr={[0, 3]} display="inline">
                  Status:
                </Text>
                {evaluationPerformance.published_at ? (
                  <Badge variant="solid" colorScheme="green">
                    <Text fontSize="md">Publicado</Text>
                  </Badge>
                ) : (
                  <Badge variant="solid" colorScheme="blue">
                    <Text fontSize="md">Em Período de avaliação</Text>
                  </Badge>
                )}
              </Flex>
              <Flex
                flexDir={['column', 'row']}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex alignItems="center">
                  <Text flexWrap="nowrap" fontSize={['md', 'xl']} mr="35px">
                    Data de Registro:
                  </Text>
                  {id ? (
                    <Text as="b" fontSize={['md', 'xl']}>
                      {evaluationPerformance?.created_at}
                    </Text>
                  ) : (
                    <Text as="b" fontSize={['md', 'xl']}>
                      {format(currentDate, 'dd/MM/yyyy')}
                    </Text>
                  )}
                </Flex>

                {id && (
                  <Flex width={['full', '380px']} alignItems="center">
                    <Text fontSize={['md', 'xl']} mr={3}>
                      Data de Atualização:
                    </Text>

                    <Text as="b" fontSize={['md', 'lg']}>
                      {evaluationPerformance?.updated_at}
                    </Text>
                  </Flex>
                )}
              </Flex>

              {((id && ratedUser?.id !== Number(user.id)) || !id) && (
                <Flex
                  flexDir={['column', 'row']}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex alignItems="center" justifyContent="center">
                    <Text fontSize={['md', 'xl']} mr="20.2px">
                      Nome do Avaliado:
                    </Text>

                    {ratedUser && (
                      <SelectChakra
                        name="rated_user_id"
                        options={[
                          { label: ratedUser.name, value: ratedUser.id },
                        ]}
                        isInline
                        readOnly
                      />
                    )}
                  </Flex>
                  <Flex width={['', '380px']} alignItems="center">
                    <Text fontSize={['md', 'xl']} mr="26px">
                      Cargo do Avaliado:
                    </Text>
                    <Text as="b" fontSize={['md', 'lg']}>
                      {id && ratedUser
                        ? ratedUser.responsibility?.name
                        : selectedUser?.responsibility?.name || '-'}
                    </Text>
                  </Flex>
                </Flex>
              )}

              {/* Exibe o nome do avaliador apenas se não for a pessoa que está logada */}
              {(!id || liderEvaluator?.id !== Number(user.id)) && (
                <Flex
                  flexDir={['column', 'row']}
                  justifyContent="space-between"
                >
                  <Flex alignItems="center">
                    <>
                      <Text fontSize={['md', 'xl']} mr="13px">
                        Nome do Avaliador:
                      </Text>
                      <Text as="b" fontSize={['md', 'xl']}>
                        {!id ? user.name : liderEvaluator?.name}
                      </Text>
                    </>
                  </Flex>
                  <Flex alignItems="center" width={['', '380px']}>
                    <Text fontSize={['md', 'xl']} mr="20px">
                      Cargo do Avaliador:
                    </Text>
                    <Text as="b" fontSize={['md', 'lg']}>
                      {!id ? user.name : liderEvaluator?.responsibility?.name}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Box>
          ) : (
            <Box width="calc(100% - 25%)">
              <Flex alignItems="center" mb={4}>
                <Text fontSize={['md', 'xl']} mr={[0, 3]} display="inline">
                  Status:
                </Text>
                <Skeleton width="100px" height="27px" />
              </Flex>
              <Flex
                flexDir={['column', 'row']}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex alignItems="center">
                  <Text fontSize={['md', 'xl']} mr="35px">
                    Data de Registro:
                  </Text>
                  <Skeleton width="100px" height="27px" />
                </Flex>

                {id && (
                  <Flex width={['', '380px']} alignItems="center">
                    <Text fontSize={['md', 'xl']} mr={3}>
                      Data de Atualização:
                    </Text>

                    <Skeleton width="100px" height="27px" />
                  </Flex>
                )}
              </Flex>
              <Flex
                flexDir={['column', 'row']}
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Text fontSize={['md', 'xl']} mr="20.2px">
                    Nome do Avaliado:
                  </Text>
                  <Skeleton width="100px" height="27px" />
                </Flex>
                <Flex width={['', '380px']} alignItems="center">
                  <Text fontSize={['md', 'xl']} mr="26px">
                    Cargo do Avaliado:
                  </Text>
                  <Skeleton width="100px" height="27px" />
                </Flex>
              </Flex>
              {}
              <Flex flexDir={['column', 'row']} justifyContent="space-between">
                <Flex alignItems="center">
                  <Text fontSize={['md', 'xl']} mr={3}>
                    Nome do Avaliador:
                  </Text>

                  <Skeleton width="100px" height="27px" />
                </Flex>
                <Flex alignItems="center" width={['', '380px']}>
                  <Text fontSize={['md', 'xl']} mr="19px">
                    Cargo do Avaliador:
                  </Text>
                  <Skeleton width="100px" height="27px" />
                </Flex>
              </Flex>
            </Box>
          )}

          <Box mt={10}>
            {(ratedUser || selectedUser) && (
              <Box display={!toogleNote ? 'block' : 'none'}>
                <Table
                  theadData={tableTitles.concat(column)}
                  loading={loading || loadingOption}
                  // freezeThead
                >
                  {!!performDimensions.length &&
                    performDimensions?.map((dim, i) => {
                      return (
                        <Fragment key={dim.id}>
                          {dim.dimension_concepts?.some((concept) => {
                            return concept.responsibilities.find((resp) => {
                              if (id && ratedUser) {
                                return resp.id === ratedUser.responsibility?.id;
                              }
                              return (
                                resp.id === selectedUser?.responsibility?.id
                              );
                            });
                          }) && (
                            <>
                              <Box background="#eeeeee35" as="tr">
                                <Box colSpan={8} as="td" py={3}>
                                  <Text ml={3} as="b">
                                    {dim.name}
                                  </Text>
                                </Box>
                              </Box>

                              {dim.dimension_concepts
                                ?.filter((concept) =>
                                  concept.responsibilities.find((resp) => {
                                    if (id && ratedUser) {
                                      return (
                                        resp.id === ratedUser.responsibility?.id
                                      );
                                    }
                                    return (
                                      resp.id ===
                                      selectedUser?.responsibility?.id
                                    );
                                  }),
                                )
                                .map((conc, j) => {
                                  const scoredConcept = conceptDimensions?.find(
                                    (dimConc) => {
                                      return dimConc.id === Number(conc.id);
                                    },
                                  );

                                  const verifyNotesRecognition = notes.some(
                                    (nt) =>
                                      nt.pr_dimension_concept_id === conc.id &&
                                      nt.pr_note_type_id === 1,
                                  );
                                  const verifyNotesWarning = notes.some(
                                    (nt) =>
                                      nt.pr_dimension_concept_id === conc.id &&
                                      nt.pr_note_type_id === 2,
                                  );
                                  const conceptionsNote = notes.filter((nt) => {
                                    return (
                                      nt.pr_dimension_concept_id === conc.id
                                    );
                                  });
                                  return (
                                    <RowDimension
                                      key={`${conc.id}-${j.toString()}`}
                                      index={j}
                                      indexDimension={i}
                                      isRatedUser={
                                        ratedUser?.id === Number(user.id)
                                      }
                                      dimensionId={dim.id}
                                      concept={conc}
                                      score={performScore}
                                      allowMaxScore={verifyNotesRecognition}
                                      allowMinScore={verifyNotesWarning}
                                      allowViewScore={
                                        liderEvaluator?.id ===
                                          Number(user.id) ||
                                        ratedUser?.id === Number(user.id)
                                      }
                                      allowFillScore={
                                        liderEvaluator?.id ===
                                          Number(user.id) &&
                                        !evaluationPerformance.published_at
                                      }
                                      allowWriteNote={
                                        !evaluationPerformance.published_at &&
                                        ratedUser?.id !== Number(user.id)
                                      }
                                      countNote={conceptionsNote.length}
                                      initialValue={
                                        handleShowSavedScore() &&
                                        scoredConcept?.score_id
                                          ? Number(scoredConcept?.score_id)
                                          : null
                                      }
                                      onClickNote={() => {
                                        !!conceptionsNote.length ||
                                          liderEvaluator?.id ===
                                            Number(user.id);
                                        handleSelectedDimensionConcept(conc.id);
                                      }}
                                      onClickScore={(value) => {
                                        handleCalculateDimensionConcept(
                                          conc.id,
                                          value,
                                        );
                                      }}
                                    />
                                  );
                                })}
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                </Table>
                <Flex justifyContent="space-between" mt={3} mr={3} mb="50px">
                  <Box>
                    <Text as="b">Observações:</Text>
                    <Text>
                      1- Marque apenas uma opção por linha / dimensão;
                    </Text>
                    <Text>
                      2- Para indicar &quot;Desempenho{' '}
                      <strong>muito abaixo</strong> do esperado&quot; ou
                      &quot;Desempenho <strong>muito acima</strong> do
                      esperado&quot; <br /> há necessidade de se ter pelo menos
                      uma anotação de &quot;Melhoria/Advertência&quot; ou de
                      &quot;Reconhecimento&quot;.
                    </Text>
                  </Box>
                  {/* ////// Pontuação media \\\\\\

                  <Flex justifyContent="end">
                    <Text as="b">
                      Pontuação Média:
                      {` ${
                        totalScore > 0 && countConcepts > 0
                          ? (totalScore / countConcepts).toFixed(2)
                          : 0
                      }`}
                    </Text>
                  </Flex> */}
                </Flex>
              </Box>
            )}
          </Box>
          <ModalChakra
            title="Tem certeza que deseja salvar a pontuação da avaliação?"
            onClose={onClose}
            isOpen={isOpen}
            modalFooter={false}
          >
            <Text>
              Após salvar você poderá alterar a pontuação definida quando
              precisar.
            </Text>
            <Flex mt="30px" justifyContent="end">
              <Button colorScheme="red" onClick={() => onClose()} mr={4}>
                Cancelar
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  formRef.current?.submitForm();
                  onClose();
                }}
              >
                Salvar
              </Button>
            </Flex>
          </ModalChakra>
        </Box>
      </Form>
      <Box display={toogleNote ? 'block' : 'none'}>
        <Flex>
          <IconButton
            aria-label="Voltar"
            icon={<FiArrowLeft />}
            size="lg"
            borderRadius="md"
            mr={3}
            onClick={() => {
              setToogleNote(!toogleNote);
              // formRefNote.current?.submitForm();
              // setSelectedDimension(null);
            }}
          />
          <Text id="anotacao" fontSize="3xl" as="b">
            Anotação de Desempenho
          </Text>
        </Flex>

        {!!performDimensions?.length && (
          <Box>
            <Form
              ref={formRefNotePublish}
              onSubmit={(data) => handleCreateNote(data)}
            >
              <Flex my={3}>
                <Text fontSize="lg" mr={3}>
                  Conceito:
                </Text>
                <Box>
                  <Text fontSize="lg" as="b">
                    {selectedDimensionConcepts?.name}
                  </Text>
                  {/* <Text fontSize="lg">{selectedDimensionConcepts?.name}</Text> */}
                </Box>
              </Flex>

              {toogleNote && (
                <Tab
                  defaultIndex={0}
                  fadeEffect
                  /* defaultIndexCustom={notes.findIndex(
                  (nt) => nt.pr_dimension_id === selectedDimension?.id,
                  )} */
                  onClickTab={(noteId, count) => {
                    setSelectCredentialNote({ id: noteId, count });
                    // console.log(noteId, ' selecionada aqui');
                  }}
                  // focusTab={selectedCredentialNote?.id}
                  onClickAdd={() =>
                    handleAddAnnotation(selectedDimensionConcepts?.id)
                  }
                  onClickRemove={
                    (value, count) => {
                      onOpenDelete();
                      setDeleteNote({ id: value, count: count || 0 });
                    }
                    // handleRemoveAnnotation(value, stored)
                  }
                  /* onChange={(index) => handleTab(index)} */
                  /* defaultIndex={!notes.length ? 0 : -1} */
                  allowAdd={
                    !evaluationPerformance.published_at &&
                    ratedUser?.id !== Number(user.id)
                  }
                  addRemoveTab
                  data={notes
                    .filter(
                      (ntFilter) =>
                        ntFilter.pr_dimension_concept_id ===
                        selectedDimensionConcepts?.id,
                    )
                    .map((note, idx) => {
                      // const numberNote = idx;

                      // const idx2 = dim.id > 1 ? idx + 1 : idx;
                      // const isAvaliator = Number(user.id) === note.writer?.id;

                      return {
                        _selected: note.published_at ? '#FFF' : '#000',
                        color: note.published_at ? '#FFF' : '#000',
                        background: note.published_at ? '#48BB78' : '#fff',
                        show:
                          note.pr_dimension_concept_id ===
                          selectedDimensionConcepts?.id,
                        id: note.id,
                        count: note?.count,
                        allowDelete: !note.published_at,
                        stored: !!note.id,
                        new: verifyNewNote(note.id),
                        title: note.id
                          ? `Anotação de ${
                              note.writer?.name ? note.writer?.name : user.name
                            } - ${note.id}`
                          : `Nova Anotação - ${note.count}`,
                        content: (
                          <Box key={note.id} mt={6}>
                            <Flex
                              flexDir={['column', 'row']}
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Flex
                                flexDir={['column', 'row']}
                                alignItems={['', 'center']}
                              >
                                <Text width="180px" fontSize="xl" mr={3}>
                                  Tipo de Anotação:
                                </Text>
                                {note.published_at ? (
                                  <InputChakra
                                    isInline
                                    name={`notes[${idx}].pr_note_type_id`}
                                    defaultValue={note?.note_type?.name}
                                    disabled={
                                      !canCreatePerformanceReviews ||
                                      !!note.published_at
                                    }
                                  />
                                ) : (
                                  <SelectChakra
                                    disabled={!canCreatePerformanceReviews}
                                    placeholder="Selecione"
                                    isInline
                                    name={`notes[${idx}].pr_note_type_id`}
                                    options={typesNotes.map((tpNote) => {
                                      return {
                                        label: tpNote.name,
                                        value: tpNote.id,
                                      };
                                    })}
                                    defaultValue={note?.pr_note_type_id?.toString()}
                                  />
                                )}
                              </Flex>

                              <Flex
                                justifyContent="end"
                                minHeight="50px"
                                alignItems="center"
                              >
                                <>
                                  {canCreatePerformanceReviews &&
                                    note.status_id !== 27 && (
                                      <>
                                        {ratedUser?.id !== Number(user.id) && (
                                          <Flex
                                            alignItems="center"
                                            mr={[0, 10]}
                                            mt={[4, 0]}
                                          >
                                            <Flex alignItems="center">
                                              <FormLabel
                                                htmlFor="publish"
                                                mb={1}
                                                fontWeight={500}
                                                fontSize="xl"
                                              >
                                                publicar?
                                              </FormLabel>
                                              <Switch
                                                id="publish"
                                                name={`note[${idx}].publish`}
                                                onChange={() => {
                                                  setPublish(!publish);
                                                }}
                                                // value={publish ? 1 : 0}
                                                isChecked={publish}
                                                defaultIsChecked={
                                                  !!note.published_at
                                                }
                                                mb={1}
                                              />
                                            </Flex>

                                            <Button
                                              isLoading={loadingButton}
                                              loadingText="Carregando ..."
                                              colorScheme="green"
                                              // width="100px"
                                              height="50px"
                                              mx={4}
                                              onClick={() => {
                                                publish
                                                  ? onOpenPublish()
                                                  : formRefNotePublish.current?.submitForm();
                                                // console.log('click');
                                                // formRefNotePublish.current?.submitForm();
                                              }}
                                            >
                                              <Text fontSize="xl">Salvar</Text>
                                            </Button>
                                            {/* <Button
                                          // disabled // {!canCreatePerformanceReviews}
                                          colorScheme="blue"
                                          width="100px"
                                          height="50px"
                                          value={1}
                                          name="publish_button"
                                          onClick={() => {
                                            // setPublish(true);
                                            formRefNotePublish.current?.submitForm();
                                            // handleNotePublish(note.id)
                                          }}
                                        >
                                          Publicar
                                        </Button> */}
                                          </Flex>
                                        )}
                                      </>
                                    )}

                                  {note.status_id === 27 && (
                                    <Flex mt={[4, 0]}>
                                      {/* <Button
                                      variant="outline"
                                      colorScheme="green"
                                      // width="100px"
                                      height="50px"
                                      mr={3}
                                    >
                                      Salvar Rascunho
                                    </Button> */}

                                      <Badge
                                        fontSize="lg"
                                        variant="outline"
                                        colorScheme="green"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        publicado em{' '}
                                        {note.published_at
                                          ? format(
                                              parseISO(note.published_at),
                                              "dd/MM/yyyy 'às' HH:mm",
                                            )
                                          : ''}
                                      </Badge>
                                    </Flex>
                                  )}
                                </>
                              </Flex>
                            </Flex>

                            <Flex mt={4}>
                              <Text fontSize="lg" mr={3}>
                                Anotação de:{' '}
                              </Text>
                              <Text fontSize="lg" as="b">
                                {note.writer?.name ?? user.name}
                              </Text>
                            </Flex>

                            {/* <Flex justifyContent="end">

                        </Flex> */}

                            {/* <InputChakra
                              type="hidden"
                              name="publish"
                              defaultValue={Number(!publish)}
                            /> */}
                            <InputChakra
                              type="hidden"
                              name={`notes[${idx}].count`}
                              defaultValue={note?.count}
                            />
                            <InputChakra
                              type="hidden"
                              name={`notes[${idx}].id`}
                              defaultValue={note.id}
                            />
                            <InputChakra
                              type="hidden"
                              name={`notes[${idx}].pr_dimension_concept_id`}
                              defaultValue={note.pr_dimension_concept_id}
                            />
                            <Flex
                              flexDir={['column', 'row']}
                              alignItems={['none', 'center']}
                            >
                              <Box width="150px" height="auto">
                                <Text>Descrição do fato</Text>
                              </Box>

                              <Box
                                mt={4}
                                rounded="sm"
                                width={['auto', '1200px']}
                              >
                                <TextareaChakra
                                  style={{ opacity: 1 }}
                                  // id={`desc-${dim.id}}
                                  rows={5}
                                  placeholder="Digite aqui"
                                  name={`notes[${idx}].fact_description`}
                                  defaultValue={note.fact_description}
                                  disabled={
                                    !canCreatePerformanceReviews ||
                                    !!note.published_at
                                  }
                                />
                              </Box>
                            </Flex>
                            <Flex
                              flexDir={['column', 'row']}
                              alignItems={['none', 'center']}
                            >
                              <Box width={['auto', '150px']} height="auto">
                                <Text>Avaliação / Consequência</Text>
                              </Box>

                              <Box
                                mt={4}
                                rounded="sm"
                                width={['auto', '1200px']}
                              >
                                <TextareaChakra
                                  style={{ opacity: 1 }}
                                  rows={5}
                                  placeholder="Digite aqui"
                                  name={`notes[${idx}].evaluation_consequence`}
                                  defaultValue={note.evaluation_consequence}
                                  disabled={
                                    !canCreatePerformanceReviews ||
                                    !!note.published_at
                                  }
                                />
                              </Box>
                            </Flex>
                            <Flex
                              flexDir={['column', 'row']}
                              alignItems={['none', 'center']}
                            >
                              <Box width="150px" height="auto">
                                <Text>Recomendação</Text>
                              </Box>

                              <Box
                                mt={4}
                                rounded="sm"
                                width={['auto', '1200px']}
                              >
                                <TextareaChakra
                                  style={{ opacity: 1 }}
                                  rows={5}
                                  placeholder="Digite aqui"
                                  name={`notes[${idx}].recommendation`}
                                  defaultValue={note.recommendation}
                                  disabled={
                                    !canCreatePerformanceReviews ||
                                    !!note.published_at
                                  }
                                />
                              </Box>
                            </Flex>
                          </Box>
                        ),
                      };
                    })}
                />
              )}
              {/* ____________ PUBLISH ____________ */}
              <ModalChakra
                title="Tem certeza que deseja publicar essa Anotação?"
                onClose={onClosePublish}
                isOpen={isOpenPublish}
                modalFooter={false}
              >
                <Text>
                  <b>OBS:</b> Após publicar não será mais possivel editá-la ou
                  apagá-la.
                </Text>
                <Text>
                  Essa anotação estará dispónivel para o avaliado visualizar.
                </Text>
                <Flex mt="30px" justifyContent="end">
                  <Button
                    colorScheme="gray"
                    onClick={() => onClosePublish()}
                    mr={4}
                  >
                    Cancelar
                  </Button>

                  <Button
                    colorScheme="green"
                    onClick={() => {
                      formRefNotePublish.current?.submitForm();
                      onClosePublish();
                    }}
                  >
                    Publicar
                  </Button>
                </Flex>
              </ModalChakra>
              <ModalChakra
                title="Tem certeza que deseja publicar essa Avaliação?"
                onClose={onClosePublishEvaluation}
                isOpen={isOpenPublishEvaluation}
                modalFooter={false}
              >
                <Text mb={1}>
                  <b>OBS:</b> Após publicar não será mais possivel editá-la ou
                  apagá-la.
                </Text>
                <Text>
                  Essa avaliação estará disponível para o avaliado visualizar,
                  incluindo todas as anotações publicadas nela presentes e a
                  pontuação definida.
                </Text>

                {/* Deve trazer as anotações de todos os usuários, não apenas do usuário logado na avaliação */}
                {/* {notes.some((nt) => !nt.published_at) && (
                  <>
                    <Text fontWeight="700" color="red.500" mt={2} mb={1}>
                      Atenção:
                    </Text>
                    <Text color="red.500">
                      Ainda há anotações não publicadas suas ou de outros
                      avaliadores nessa avaliação, se você prosseguir, não será
                      possível publicá-las mais tarde, deseja realmente publicar
                      a avaliação?
                    </Text>
                  </>
                )} */}
                <Flex mt="30px" justifyContent="end">
                  <Button
                    colorScheme="gray"
                    onClick={() => onClosePublishEvaluation()}
                    mr={4}
                  >
                    Cancelar
                  </Button>

                  <Button
                    colorScheme="green"
                    onClick={() => {
                      // formRefNotePublish.current?.submitForm();
                      onClosePublishEvaluation();
                      handlePublishEvaluation();
                    }}
                  >
                    Publicar
                  </Button>
                </Flex>
              </ModalChakra>
            </Form>
          </Box>
        )}
      </Box>

      {/* _________ DELETE _________ */}
      <ModalChakra
        title="Deletar Anotação?"
        onClose={onCloseDelete}
        isOpen={isOpenDelete}
        modalFooter={false}
      >
        <Text>Tem certeza que deseja Deletar essa Anotação?</Text>
        <Flex mt="30px" justifyContent="end">
          <Button colorScheme="gray" onClick={() => onCloseDelete()} mr={4}>
            Cancelar
          </Button>
          {deleteNote && (
            <Button
              colorScheme="red"
              onClick={() => {
                handleRemoveAnnotation();
                onCloseDelete();
              }}
            >
              Deletar
            </Button>
          )}
        </Flex>
      </ModalChakra>
    </>
  );
};

export default ShowPerformanceReview;
