import React, { useCallback, useEffect, useRef, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { FiMail, FiLock, FiArrowLeft } from 'react-icons/fi';
import { useRouteMatch, Link as ReactLink } from 'react-router-dom';

import {
  useToast,
  Button,
  Stack,
  Image,
  Flex,
  Heading,
  Box,
  useColorMode,
  Text,
  Divider,
  IconButton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import iconGoogle from '~/assets/google.svg';
import logoBlueImg from '~/assets/logo-blue.svg';
import logoImg from '~/assets/logo.svg';
import { ColorModeSwitcher } from '~/ColorModeSwitcher';
import { useAuth } from '~/hooks/auth';
import { useNotification } from '~/hooks/notification';
import Input from '~/shared/components/InputChakra';
import Select from '~/shared/components/InputChakra/SelectChakra';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

interface SignInParams {
  email: string;
}

interface SignInFormData {
  email: string;
  password: string;
}
interface SignInFormCompanyData {
  company_id: number | string;
}

interface User {
  id: number;
  name: string;
  email: string;
}

export interface Subscriptions {
  id: number;
  uuid_barte: string;
  subscription_buyer_id: number;
  company_id: number;
  subscription_status_id: number;
  subscription_plan_id: number;
  subscription_period_id: number;
  licenses_qty?: number;
  subscription_promotional_code_id: number;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  subscription_licenses_count: number;
  subscription_available_licenses_count: number;
}

interface Company {
  company_id: number;
  company_name: string;
  subscriptions: Subscriptions[] | null;
  value: number;
  label: string;
}

interface StartLogin {
  user: User;
  companies: Company[];
}

interface GoogleObj {
  googleId: string;
  imageUrl: string;
  email: string;
}

interface BwGoogle {
  profileObj: {
    email: string;
    googleId: string;
    imageUrl: string;
  };
}

const SignIn: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = { light: 'gray.50', dark: 'rgb(18, 18, 20)' };
  const bgInputColor = { light: '#fff', dark: 'rgb(18, 18, 20)' };
  const bgBorderInputColor = { light: 'inherit', dark: 'rgb(18, 18, 20)' };
  const bgBoxColor = { light: '#fff', dark: 'rgb(32, 32, 36)' };
  const textColor = { light: 'gray.400', dark: 'rgb(225, 225, 230)' };
  const textInputColor = { light: 'black', dark: 'rgb(225, 225, 230)' };
  const textTitle = { light: 'gray.700', dark: 'rgb(225, 225, 230)' };

  const [btnActive, setBtnActive] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const formCompanyRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [inputEmailFocus, setInputEmailFocus] = useState(true);
  const [initialData, setInitialData] = useState({} as SignInParams);
  const [password, setPassword] = useState<string | null>(null);
  const [user, setUser] = useState<User>({} as User);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [next, setNext] = useState(false);
  const [googleObj, setGoogleObj] = useState<GoogleObj>({} as GoogleObj);

  const { signIn } = useAuth();
  const { getNotifications } = useNotification();
  const addToast = useToast();

  const { params } = useRouteMatch<SignInParams>();

  useEffect(() => {
    if (params.email) {
      setInitialData({
        email: params.email,
      });
      setInputEmailFocus(false);
      formRef.current?.getFieldRef('password').focus();
    }
  }, [params.email]);

  const handleStartSubmit = useCallback(
    async (data: SignInFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido!'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<StartLogin>('start/sessions', {
          email: data.email,
          password: data.password,
        });

        if (!response.data) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreu um erro ao fazer login, cheque suas credenciais.',
          });

          return;
        }

        if (response.data.companies.length === 1) {
          await signIn({
            email: data.email,
            password: data.password,
            company_id: response.data.companies[0].company_id,
            is_subscribed: !!response.data.companies[0].subscriptions?.length,
          });

          await getNotifications(response.data.user.id);

          api.post('/shifts-logs');
        } else {
          setUser(response.data.user);
          setCompanies(
            response.data.companies?.map((company) => {
              return {
                ...company,
                value: company.company_id,
                label: company.company_name,
              };
            }),
          );
          setPassword(data.password);

          setNext(true);

          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque suas credenciais.',
        });
      }
    },
    [addToast, signIn, getNotifications],
  );

  /* const responseGoogle = useCallback(
    async (responseGoogleData) => {
      if (
        responseGoogleData.error ||
        responseGoogleData.error === 'idpiframe_initialization_failed'
      ) {
        return;
      }
      setLoading(true);

      if (!responseGoogleData.profileObj) {
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description: 'Ocorreu um erro, tente novamente.!',
        });
      }

      try {
        const response = await api.post<StartLogin>('start/sessions', {
          email: responseGoogleData.profileObj.email,
          password: null,
          googleId: responseGoogleData.profileObj.googleId,
          imageUrl: responseGoogleData.profileObj.imageUrl,
        });

        if (!response.data) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreu um erro ao fazer login, cheque suas credenciais.',
          });

          return;
        }

        if (response.data.companies.length === 1) {
          await signIn({
            email: responseGoogleData.profileObj.email,
            password: '',
            company_id: response.data.companies[0].company_id,
            googleId: responseGoogleData.profileObj.googleId,
            imageUrl: responseGoogleData.profileObj.imageUrl,
          });
        } else {
          setUser(response.data.user);
          setCompanies(
            response.data.companies?.map((company) => {
              return {
                ...company,
                value: company.company_id,
                label: company.company_name,
              };
            }),
          );
          setPassword(null);

          setNext(true);
          setLoading(false);

          setGoogleObj({
            email: responseGoogleData.profileObj.email,
            googleId: responseGoogleData.profileObj.googleId,
            imageUrl: responseGoogleData.profileObj.imageUrl,
          });
        }
      } catch (err) {
        setLoading(false);
        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque suas credenciais.',
        });
      }
    },
    [addToast, signIn],
  ); */

  const handleSubmit = useCallback(
    async (data: SignInFormCompanyData) => {
      try {
        formCompanyRef.current?.setErrors({});

        const schema = Yup.object().shape({
          company_id: Yup.string().required('Selecione uma empresa!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await signIn({
          email: user.email,
          password: password || '',
          company_id: Number(data.company_id),
          googleId: googleObj.googleId,
          imageUrl: googleObj.imageUrl,
          is_subscribed: !!companies.find(
            (comp) => comp.company_id === Number(data.company_id),
          )?.subscriptions?.length,
        });

        await getNotifications(user.id);

        api.post('/shifts-logs', null, {
          params: {
            company: data.company_id,
          },
        });
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formCompanyRef.current?.setErrors(errors);

          return;
        }

        if (err.response?.status < 500) {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque suas credenciais.',
        });
      }
    },
    [
      signIn,
      addToast,
      password,
      user.email,
      googleObj.googleId,
      googleObj.imageUrl,
      user.id,
      getNotifications,
      companies,
    ],
  );

  const handleActiveBtn = useCallback(() => {
    if (
      formRef.current?.getFieldValue('email') &&
      formRef.current?.getFieldValue('password')
    ) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
  }, []);

  const handleGoBack = useCallback(() => {
    setNext(false);
    setUser({} as User);
    setCompanies([]);
  }, []);

  return (
    <>
      <Box
        w="full"
        display="flex"
        flexDir="column"
        alignItems="center"
        minH="100vh"
        bg={bgColor[colorMode]}
      >
        <Box
          display="flex"
          flex="1 1 0%"
          flexDir="column"
          alignItems="center"
          w="100%"
          padding="32px"
          maxW={1000}
        >
          <Flex
            flex="1 1 0%"
            justifyContent="center"
            w="full"
            alignItems="center"
            padding="28px 0 50px"
          >
            <Flex
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Flex flexDir="column" w="full" maxW="480px">
                <Image
                  width={150}
                  objectFit="contain"
                  src={colorMode === 'dark' ? logoImg : logoBlueImg}
                  alt="MyIPAC"
                  mb={10}
                />

                <Heading
                  as="h1"
                  fontSize={['44px', '54px']}
                  lineHeight="64px"
                  mb="24px"
                  color={textTitle[colorMode]}
                >
                  Faça seu login <br /> na plataforma
                </Heading>
              </Flex>
              <Box
                w="full"
                maxW="480px"
                bg={bgBoxColor[colorMode]}
                rounded="sm"
                padding={{ base: '32px', sm: '64px' }}
                pos="relative"
              >
                {/* Necessário adaptar todos o schema de cores para utilizar o colorSwitcher */}
                {/*  <ColorModeSwitcher /> */}
                {next ? (
                  <Form
                    ref={formCompanyRef}
                    initialData={initialData}
                    onSubmit={handleSubmit}
                  >
                    <Flex mb={5}>
                      <IconButton
                        aria-label="Voltar"
                        icon={<FiArrowLeft />}
                        variant="ghost"
                        color="blue.500"
                        size="sm"
                        onClick={handleGoBack}
                      />

                      <Text
                        fontSize="md"
                        color={textColor[colorMode]}
                        lineHeight="1.25"
                      >
                        Quase lá! Selecione a empresa a qual deseja realizar
                        atividades e clique em continuar:
                      </Text>
                    </Flex>

                    <Select
                      autoFocus
                      name="company_id"
                      options={companies}
                      placeholder="..."
                    />

                    <Button
                      mt={5}
                      variant="solid"
                      colorScheme="blue"
                      isLoading={loading}
                      isDisabled={loading}
                      width="100%"
                      size="lg"
                      type="submit"
                    >
                      CONTINUAR
                    </Button>
                  </Form>
                ) : (
                  <Form
                    ref={formRef}
                    initialData={initialData}
                    onSubmit={handleStartSubmit}
                  >
                    <Input
                      name="email"
                      icon={FiMail}
                      placeholder="E-mail"
                      autoComplete="email"
                      autoFocus={inputEmailFocus}
                      background={bgInputColor[colorMode]}
                      borderColor={bgBorderInputColor[colorMode]}
                      color={textInputColor[colorMode]}
                      onChange={handleActiveBtn}
                      disabled={loading || false}
                    />

                    <Input
                      name="password"
                      icon={FiLock}
                      type="password"
                      autoComplete="current-password"
                      placeholder="Senha"
                      isPassword
                      background={bgInputColor[colorMode]}
                      borderColor={bgBorderInputColor[colorMode]}
                      color={textInputColor[colorMode]}
                      onChange={handleActiveBtn}
                      disabled={loading || false}
                    />

                    <ReactLink to="/esqueci-senha">
                      <Heading size="xs" color="blue.500">
                        Esqueci minha senha
                      </Heading>
                    </ReactLink>

                    <Button
                      mt={5}
                      variant="solid"
                      colorScheme="blue"
                      isLoading={loading}
                      isDisabled={!btnActive || loading}
                      width="100%"
                      size="lg"
                      type="submit"
                    >
                      ENTRAR
                    </Button>

                    <Stack isInline mt={5} justifyContent="center">
                      <Text
                        fontSize="sm"
                        color={textColor[colorMode]}
                        lineHeight="1.25"
                      >
                        Não tem conta?
                      </Text>
                      <ReactLink to="/cadastro">
                        <Heading size="xs" color="blue.500">
                          Registre-se
                        </Heading>
                      </ReactLink>
                    </Stack>
                    {/* Necessário atualizar as credenciais do usuário oauth2 para utilizar essa forma de login */}
                    {/* <Box
                      w="full"
                      height={4}
                      display="flex"
                      justifyContent="space-between"
                      mt={5}
                      opacity={0.7}
                    >
                      <Divider w="full" mr={8} />
                      <Divider w="full" />
                    </Box>

                    <Stack isInline alignItems="center" mt={5}>
                      <Text
                        fontSize="sm"
                        color={textColor[colorMode]}
                        lineHeight="1.25"
                        w={140}
                      >
                        Ou entre com
                      </Text>

                      <GoogleLogin
                        clientId="870788645367-5l7l1lteh8jt8olntgm79tk0g4p9cn9v.apps.googleusercontent.com"
                        render={(renderProps) => (
                          <Button
                            variant="outline"
                            w="full"
                            size="lg"
                            onClick={renderProps.onClick}
                            isDisabled={renderProps.disabled || loading}
                          >
                            <Image
                              src={iconGoogle}
                              objectFit="contain"
                              height="18px"
                              mr={2}
                            />
                            Google
                          </Button>
                        )}
                        buttonText="Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy="single_host_origin"
                      />
                    </Stack> */}
                  </Form>
                )}
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;
