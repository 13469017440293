import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import {
  RadioGroup,
  Radio,
  FormErrorMessage,
  Input,
  FormControl,
  Heading,
  Flex,
  Tooltip,
  Icon as IconChakra,
  Stack,
  ResponsiveValue,
  Text,
} from '@chakra-ui/react';
import { useField } from '@unform/core';

interface RadioData {
  value: string;
  label: number | string;
  colorScheme?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
}

type flexDir = 'column' | 'row';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  containerStyle?: object;
  options: RadioData[];
  isInline?: boolean;
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  help?: string;
  mb?: number | string;
  flexDir?: flexDir[];
  onChangeCustom?(value: string): void;
  wrapOption?: 'nowrap' | 'normal';
}

const RadioChakra: React.FC<InputProps> = ({
  name,
  label,
  containerStyle,
  options,
  isInline = false,
  help,
  mb,
  justifyContent,
  flexDir,
  onChangeCustom,
  wrapOption = 'normal',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // const inputRef = useRef<HTMLInputElement[]>([]);
  // O valor padrão que passamos para o componente é apenas uma string, não um array como CheckboxChakra
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [value, setValue] = useState<string>(defaultValue || '');

  useEffect(() => {
    // console.log(inputRef?.current?.value);
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: () => {
        const dv = defaultValue || '';
        setValue(dv);
        // O estado está atualizando o valor da prop value do componente input, dessa forma a ref também é atualizada
      },
      setValue: (ref: HTMLInputElement, values: string) => {
        setValue(values);
      },
    });
  }, [fieldName, registerField, defaultValue]);

  return (
    <FormControl
      h="full"
      mb={mb || 4}
      style={containerStyle}
      isInvalid={!!error}
    >
      <Flex flexDirection="column" justifyContent={justifyContent}>
        {label && (
          <Flex
            alignItems="center"
            fontWeight="medium"
            className="flex-radio-label"
          >
            <Heading
              as="h5"
              size="sm"
              mb={1}
              fontWeight={500}
              cursor="default"
              color="gray.500"
            >
              {label}
            </Heading>

            {help && (
              <Tooltip aria-label={help} label={help} zIndex={1}>
                <IconChakra
                  name="info-outline"
                  ml="auto"
                  color="gray.500"
                  className="help-radio-icon"
                />
              </Tooltip>
            )}
          </Flex>
        )}

        {/* Como esse componente mantem apenas um valor independente da quantidade de opções, podemos utilizar apenas uma ref
        para um componente auxiliar, ele apenas mantem a nossa ref atualizada, coisa que se fosse necessário fazer com o componente Radio
        seria necessário criar um array de referências, tal qual o CheckboxChakra faz. O estado que faz alterar visualmente as opções é o value
        ele também mantém o valor da ref atualizado */}
        <Input
          type="hidden"
          display="none"
          value={value}
          name={name}
          ref={inputRef}
        />

        <Stack
          direction={flexDir || (isInline ? 'row' : 'column')}
          className="radio-group-stack"
        >
          {options?.map((item) => (
            <Radio
              key={`option-r-${item.value}`}
              value={item.value}
              alignSelf="start"
              colorScheme={item.colorScheme || 'blue'}
              isDisabled={!!item.isDisabled}
              onChange={(e) => {
                if (onChangeCustom) {
                  onChangeCustom(e.target.value);
                }
                setValue(e.target.value);
              }}
              isChecked={item.value === value}
            >
              <Text whiteSpace={wrapOption}>{item.label}</Text>
            </Radio>
          ))}
        </Stack>
      </Flex>
      {error && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}
    </FormControl>
  );
};

export default RadioChakra;
