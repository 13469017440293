import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import {
  FiPlus,
  FiEdit,
  FiTrash,
  FiEye,
  FiHelpCircle,
  FiPlay,
} from 'react-icons/fi';
import { Link, useLocation, useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Tooltip,
  Text,
  Button,
  IconButton,
  useDisclosure,
  useToast,
  Badge,
  Alert,
  AlertIcon,
  Skeleton,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { format, parseISO } from 'date-fns';
import { Step } from 'intro.js';
import queryString from 'query-string';

import { useAuth } from '~/hooks/auth';
import useCan from '~/hooks/useCan';
import { User } from '~/modules/accessControl/@types/user';
import {
  Evaluator,
  WorkDeal,
  Note,
  Dimension,
  EvaluationPeriod,
} from '~/modules/gdp/@types/performanceReview';
import AlertDialog from '~/shared/components/AlertDialog';
import ButtonIcon from '~/shared/components/Button/ButtonIcon';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import Loading from '~/shared/components/Loading';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import { IPaginationProps } from '~/shared/components/Pagination';
import SectionHeader from '~/shared/components/SectionHeader';
import Table, { THeadProps } from '~/shared/components/Table';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';

interface ListAnnotation extends IPaginationProps {
  data: ListPerformanceProps[];
}

interface SelectUsersAll extends User {
  label: string;
  value: number;
}

interface PerformanceReviewEvaluation {
  total_team_evaluations_generated: number;
  total_responsible_evaluations_generated: number;
}

interface QueryParams {
  recebidas?: string;
  work_deal?: string;
  evaluation_period?: string;
}

interface EvaluationPeriodSelect extends EvaluationPeriod {
  active?: boolean;
  selected?: boolean;
  show?: boolean;
}

export interface ListPerformanceProps {
  id: number;
  company_id: number;
  work_deal_id: number;
  evaluator_user_id: number;
  rated_user_id: number;
  published_at: string;
  created_at: string;
  updated_at: string;
  count_personal_notes: number;
  count_published_personal_notes: number;
  count_general_notes: number;
  work_deal: WorkDeal;
  rated_user: User;
  evaluator_users: Evaluator[];
  evaluation_period: EvaluationPeriodSelect[];
  notes: Note[];
  dimensions: Dimension[];
}

interface CurrentWorkDeal extends WorkDealSelect {
  meses: number;
}

export interface WorkDealOptions {
  current_work_deal: CurrentWorkDeal;
  work_deals: WorkDealSelect[];
}

export interface WorkDealSelect extends WorkDeal {
  value?: number;
  label?: string;
  evaluation_periods: EvaluationPeriodSelect[];
}

const PerformanceReview: React.FC = () => {
  const cancelRefGenerateEvaluations = useRef(null);
  const formRef = useRef<FormHandles>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSelect,
    onOpen: onOpenSelect,
    onClose: onCloseSelect,
  } = useDisclosure();

  const {
    isOpen: isOpenGenerateEvaluations,
    onOpen: onOpenGenerateEvaluations,
    onClose: onCloseGenerateEvaluations,
  } = useDisclosure();

  const addToast = useToast();

  const { user } = useAuth();
  const can = useCan();

  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const wkdl = queryParams?.work_deal;
  const evpd = queryParams?.evaluation_period;

  const [loadingGenerateEvaluations, setLoadingGenerateEvaluations] = useState(
    false,
  );

  const onlyReceived = queryParams?.recebidas === '1';

  const [listAll, setListAll] = useState<SelectUsersAll[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [reload, setReload] = useState(0);
  const [selectDeleteID, setSelectDeleteID] = useState(0);
  const [listAnnotation, setListAnnotation] = useState<ListPerformanceProps[]>(
    [],
  );

  const [personalEvaluations, setPersonalEvaluations] = useState<
    ListPerformanceProps[]
  >([]);
  const [extraEvaluations, setExtraEvaluations] = useState<
    ListPerformanceProps[]
  >([]);

  const [selectedWorkDeal, setSelectedWorkDeal] = useState<WorkDealSelect>(
    {} as WorkDealSelect,
  );
  const [currentWorkDealId, setCurrentWorkDealId] = useState(0);
  const [currentEvaluationPeriodId, setCurrentEvaluationPeriodId] = useState(0);
  const [
    selectedEvaluationPeriod,
    setSelectedEvaluationPeriod,
  ] = useState<EvaluationPeriodSelect>();
  const [workDeals, setworkDeals] = useState<WorkDealSelect[]>([]);
  const [page, setPage] = useState(1);

  // const canListPerformanceReviews = can('list-performance-reviews');
  const canCreatePerformanceReviews = can('create-performance-reviews');

  const params = useMemo((): any => {
    const paramsApi: any = {
      page,
    };

    if (onlyReceived) {
      paramsApi.rated_user_id = user.id;
    } else {
      paramsApi.evaluator_user_id = user.id;
    }

    return paramsApi;
  }, [page, user.id, onlyReceived]);

  const [loading, setLoading] = useState(true);
  const [loadingDate, setLoadingDate] = useState(false);

  const [enableTourEvaluator, setEnableTourEvaluator] = useState(false);
  const [enableTourRatedUser, setEnableTourRatedUser] = useState(false);
  const [stepsEvaluator, setStepsEvaluator] = useState<Step[]>([
    {
      element: '#container',
      title: 'Nessa página, você acompanha as avaliações que você realizou.',
      intro: '',
    },
    {
      element: '#period',
      title: 'Preste atenção no periodo avaliativo.',
      intro:
        'Você pode verificar avaliações em acordo de trabalho passados, se houver.',
    },
    {
      element: '#team',
      title: 'Aqui você pode avaliar sua equipe quando bem desejar.',
      intro:
        'É preciso clicar no icone para acessar a avaliação do funcionario que deseja',
    },
    {
      element: '#extras',
      title:
        'Aqui você pode adicionar uma anotação para qualquer pessoa que esteja na lista.',
      intro:
        'Para iniciar basta clicar no (+) e selecionar o funcionario em que deseja',
    },
  ]);
  const [stepsRatedUser, setStepsRatedUser] = useState<Step[]>([
    {
      element: '#container',
      title: 'Nessa página, você acompanha como foi avaliado.',
      intro: '',
    },
    {
      element: '#period',
      title: 'Preste atenção no periodo avaliativo.',
      intro:
        'Você pode verificar avaliações em acordo de trabalho passados, se houver.',
    },
    {
      element: '#team',
      title: 'Aqui você pode visualizar suas avaliações.',
      intro:
        'É preciso clicar no icone para acessar a avaliação ou anotação feita para você.',
    },
  ]);

  const currentDate = format(new Date(), 'dd/MM/yyyy');

  const tableTitles = useMemo((): THeadProps[] => {
    const tableTitlesStart = [
      {
        title: 'ID',
      },
      {
        title: 'Avaliador',
      },
      {
        title: 'Avaliado',
      },
      {
        title: 'Cargo',
      },
    ];

    const theadNoteInfo = !onlyReceived
      ? [
          {
            title: 'Minhas Anotações (publicadas/total)',
          },
          {
            title: 'Anotações Gerais (publicadas)',
          },
        ]
      : [{ title: 'Anotações Publicadas' }];

    const tableTitlesEnd = [
      {
        title: 'Situação',
      },
      {
        title: 'Publicado em',
      },
      {
        title: 'Ações',
      },
    ];

    return [...tableTitlesStart, ...theadNoteInfo, ...tableTitlesEnd];
  }, [onlyReceived]);
  // console.log(params);

  const listUsers = useCallback(() => {
    api
      .get<User[]>('users/all')
      .then((response) => {
        const users = response.data;

        // console.log(users);
        setListAll(
          users.map((us) => {
            return {
              ...us,
              label: us.name,
              value: us.id,
            };
          }),
        );
      })
      .finally(() => setLoadingUsers(false));
  }, []);

  const listAnotationTeam = useCallback(
    (workDealId?: number, evaluationPeriodId?: number) => {
      setLoading(true);

      const { CancelToken } = axios;
      const source = CancelToken.source();

      // console.log('consulta', { ...params, workDealId, evaluationPeriodId });

      api
        .get<ListPerformanceProps[]>('reports/performance-reviews', {
          params: {
            ...params,
            work_deal_id: workDealId,
            evaluation_period_id: evaluationPeriodId,
          },
          cancelToken: source.token,
        })
        .then((response) => {
          const listAnnot = response.data;

          const extraEvaluationsEvaluation: ListPerformanceProps[] = [];
          const personalEvaluationsEvaluation: ListPerformanceProps[] = [];

          if (listAnnot.length) {
            listAnnot.forEach((lstAnt) => {
              if (onlyReceived) {
                if (lstAnt.rated_user_id === Number(user.id))
                  personalEvaluationsEvaluation.push(lstAnt);
              } else {
                const isLeaderEvaluator = lstAnt.evaluator_users.some(
                  (userEvaluator) =>
                    userEvaluator.id === Number(user.id) &&
                    userEvaluator.is_leader,
                );

                if (isLeaderEvaluator) {
                  personalEvaluationsEvaluation.push(lstAnt);
                } else {
                  extraEvaluationsEvaluation.push(lstAnt);
                }
              }
            });
          }

          setListAnnotation(listAnnot);

          setPersonalEvaluations(personalEvaluationsEvaluation);
          setExtraEvaluations(extraEvaluationsEvaluation);
        })
        .finally(() => setLoading(false));

      return () => {
        source.cancel('Cancelado pelo usuário');
      };
    },
    [params, onlyReceived, user.id],
  );

  const handleSelectEvaluationPeriod = useCallback(
    (value: string) => {
      const evaluationPeriodId = Number(value);

      if (!selectedWorkDeal?.id) return;

      // console.log(evaluationPeriodId);

      let selectedEvPeriod: EvaluationPeriodSelect | null = null;

      const workDealUpdated = {
        ...selectedWorkDeal,
        evaluation_periods: selectedWorkDeal.evaluation_periods.map((p) => {
          if (evaluationPeriodId === p.id) {
            selectedEvPeriod = p;
          }

          return {
            ...p,
            selected: evaluationPeriodId === p.id,
          };
        }),
      };

      setSelectedWorkDeal(workDealUpdated);

      if (selectedEvPeriod) {
        // Adicionar o período avaliativo selecionado
        setSelectedEvaluationPeriod(selectedEvPeriod);
      }

      listAnotationTeam(selectedWorkDeal?.id, evaluationPeriodId);
    },
    [listAnotationTeam, selectedWorkDeal],
  );

  const getWorkDealOptions = useCallback(() => {
    // console.log('carregando opções');
    setLoadingDate(true);
    api
      .get<WorkDealOptions>('/reports/performance-reviews/options', {
        params: { list_performance_review_options: 0 },
      })
      .then((response) => {
        const { current_work_deal, work_deals } = response.data;

        const currentWd: WorkDealSelect = {
          ...current_work_deal,
          data_inicio: current_work_deal
            ? format(parseISO(current_work_deal?.data_inicio), 'dd/MM/yyyy')
            : '',
          data_fim: current_work_deal
            ? format(parseISO(current_work_deal?.data_fim), 'dd/MM/yyyy')
            : '',
          evaluation_periods: current_work_deal?.evaluation_periods?.map(
            (ep) => {
              const currentEvaluationPeriodData = {
                ...ep,
                selected: ep.active,
                start_date: format(parseISO(ep.start_date), 'dd/MM/yyyy'),
                end_date: format(parseISO(ep.end_date), 'dd/MM/yyyy'),
                show: ep.start_date <= format(new Date(), 'yyyy-MM-dd'),
              };

              if (ep.active) {
                setSelectedEvaluationPeriod(currentEvaluationPeriodData);
                setCurrentEvaluationPeriodId(ep.id);
              }

              return currentEvaluationPeriodData;
            },
          ),
        };

        setCurrentWorkDealId(currentWd.id);

        if (wkdl && evpd) {
          const findWorkDeal = work_deals.find((wd) => wd.id === Number(wkdl));

          // console.log('wd found', findWorkDeal);

          if (findWorkDeal) {
            const selectedWd: WorkDealSelect = {
              ...findWorkDeal,
              data_inicio: findWorkDeal
                ? format(parseISO(findWorkDeal?.data_inicio), 'dd/MM/yyyy')
                : '',
              data_fim: findWorkDeal
                ? format(parseISO(findWorkDeal?.data_fim), 'dd/MM/yyyy')
                : '',
              evaluation_periods: findWorkDeal.evaluation_periods.map((ep) => {
                // console.log('log', Number(evpd) === ep.id, evpd, ep.id);

                const selectedEvaluationPeriodData = {
                  ...ep,
                  selected: evpd ? Number(evpd) === ep.id : false,
                  start_date: format(parseISO(ep.start_date), 'dd/MM/yyyy'),
                  end_date: format(parseISO(ep.end_date), 'dd/MM/yyyy'),
                  show: ep.start_date <= format(new Date(), 'yyyy-MM-dd'),
                };

                // console.log(ep, selectedEvaluationPeriodData);

                if (ep.active) {
                  setSelectedEvaluationPeriod(selectedEvaluationPeriodData);
                }

                return selectedEvaluationPeriodData;
              }),
            };
            setSelectedWorkDeal(selectedWd);
            listAnotationTeam(Number(wkdl), Number(evpd));
          }
        } else {
          setSelectedWorkDeal(currentWd);
        }

        // console.log(currentWd);
        setworkDeals(
          work_deals.map((wd) => {
            return {
              ...wd,
              value: wd.id,
              label: wd.nome,
              data_inicio: format(parseISO(wd.data_inicio), 'dd/MM/yyyy'),
              data_fim: format(parseISO(wd.data_fim), 'dd/MM/yyyy'),
              evaluation_periods: wd.evaluation_periods.map((ep) => {
                return {
                  ...ep,
                  start_date: format(parseISO(ep.start_date), 'dd/MM/yyyy'),
                  end_date: format(parseISO(ep.end_date), 'dd/MM/yyyy'),
                  show: ep.start_date <= format(new Date(), 'yyyy-MM-dd'),
                };
              }),
            };
          }),
        );
      })
      .finally(() => setLoadingDate(false));
  }, [wkdl, evpd, listAnotationTeam]);

  const notAllowedAccess = useMemo(
    () => !onlyReceived && !canCreatePerformanceReviews,
    [onlyReceived, canCreatePerformanceReviews],
  );

  useEffect(() => {
    if (notAllowedAccess) {
      addToast({
        position: 'top-right',
        isClosable: true,
        title: 'Acesso não permitido',
        description:
          'Você não tem permissão para visualizar a página de avaliações realizadas',
        status: 'error',
      });

      // history goback não exibe o toast
      history.push({
        pathname: '/relatorios/avaliacao-desempenho',
        search: 'recebidas=1',
      });
      return;
    }

    getWorkDealOptions();
    listUsers();
  }, [
    notAllowedAccess,
    addToast,
    getWorkDealOptions,
    listUsers,
    history,
    queryParams.recebidas,
  ]);

  useEffect(() => {
    /* Se o usuário tem permissão para visualiar as avaliações da equipe e possui os params GET abaixo então o método listAnotationTeam
    é chamado mais a frente */
    if (!notAllowedAccess && (!wkdl || !evpd)) {
      listAnotationTeam();
    }
  }, [
    notAllowedAccess,
    listAnotationTeam,
    reload,
    queryParams.recebidas,
    evpd,
    wkdl,
  ]);

  const handleDelete = useCallback(
    async (id: number) => {
      try {
        await api.delete(`reports/performance-reviews/${id}`);

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Avaliação Apagado!',
          description: `A Avaliação foi apagada com sucesso!`,
          status: 'success',
        });

        setListAnnotation((state) => state?.filter((list) => list.id !== id));
      } catch (err) {
        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Não foi possível apagar a Avaliação',
          description: err?.response.data?.error || '',
          status: 'error',
        });
      }
    },
    [addToast],
  );

  const handleFindLeaderEvaluator = useCallback(
    (evaluatorUsers: Evaluator[]) => {
      return evaluatorUsers.find((ev) => ev.is_leader);
    },
    [],
  );

  const handleSubmit = useCallback(
    (data) => {
      // console.log(data.rated_user_id);
      api
        .post('/reports/performance-reviews/evaluator', data)
        .then(() => {
          addToast({
            position: 'top-right',
            isClosable: true,
            title:
              'O usuário foi adicionado a sua lista de anotações com sucesso!',
            // description: `A Avaliação foi adicionada a sua lista de anotações extras!`,
            status: 'success',
          });

          setReload((state) => state + 1);
        })
        .catch((err) => {
          addToast({
            position: 'top-right',
            isClosable: true,
            title: 'Não foi possível adicionar Avaliação',
            description: err?.response.data?.error || '',
            status: 'error',
          });
        });
    },
    [addToast],
  );

  const handleGenerateEvaluations = useCallback(() => {
    if (!selectedEvaluationPeriod) {
      addToast({
        position: 'top-right',
        isClosable: true,
        title:
          'Não foi possível obter o período selecionado para gerar as avaliações',
        status: 'error',
      });
      return;
    }

    setLoadingGenerateEvaluations(true);

    api
      .post<PerformanceReviewEvaluation>(
        'reports/performance-reviews/evaluations',
        {
          work_deal_id: selectedWorkDeal.id,
          evaluation_period_id: selectedEvaluationPeriod.id,
          responsible_id: user.id,
          department_id: user.department_id,
        },
      )
      .then((resp) => {
        /* Renderizar novamente o componente buscando as avaliações */

        const {
          total_responsible_evaluations_generated,
          total_team_evaluations_generated,
        } = resp.data;

        const totalEvaluationsGenerated =
          total_responsible_evaluations_generated +
          total_team_evaluations_generated;

        const msg =
          totalEvaluationsGenerated > 0
            ? `Foram geradas ao todo ${totalEvaluationsGenerated} avaliações no período selecionado`
            : 'Nenhuma avaliação foi gerada no período selecionado, verifique as configurações do departamento e usuários e tente novamente';

        addToast({
          position: 'top-right',
          isClosable: true,
          title: totalEvaluationsGenerated
            ? 'Avaliações geradas com sucesso!'
            : 'Não foi possível gerar as avaliações',
          description: msg,
          status: totalEvaluationsGenerated ? 'success' : 'warning',
          duration: 7000,
        });

        setReload((state) => state + 1);
      })
      .catch((err) => {
        addToast({
          position: 'top-right',
          isClosable: true,
          title:
            'Não foi possível gerar as avaliações no período avaliativo selecionado',
          description: err?.response.data?.error || '',
          status: 'error',
          duration: 7000,
        });
      })
      .finally(() => {
        setLoadingGenerateEvaluations(false);
        onCloseGenerateEvaluations();
      });
  }, [
    addToast,
    selectedWorkDeal,
    selectedEvaluationPeriod,
    onCloseGenerateEvaluations,
    user,
  ]);

  const handleSelectWorkDeal = useCallback(
    (value: string) => {
      const selected = workDeals.find((wd) => Number(value) === wd.id);

      if (!selected) return;

      setSelectedWorkDeal({
        ...selected,
        evaluation_periods: selected?.evaluation_periods.map((period, key) => {
          return {
            ...period,
            selected: key === 0,
          };
        }),
      });

      const firstEvaluationPeriodWorkDeal = selected.evaluation_periods.length
        ? selected.evaluation_periods[0].id
        : undefined;

      listAnotationTeam(selected.id, firstEvaluationPeriodWorkDeal);
    },
    [listAnotationTeam, workDeals],
  );

  const handleShowActions = useCallback(() => {
    if (selectedWorkDeal.id > currentWorkDealId) {
      return false;
    }

    // console.log('selected', selectedWorkDeal);

    const selectedPeriod = selectedWorkDeal.evaluation_periods?.find(
      (ep) => ep.selected,
    );

    return !!selectedPeriod?.show;
  }, [currentWorkDealId, selectedWorkDeal]);

  const handlePerformanceReview = (listId: number): void => {
    const selectedPeriod = selectedWorkDeal.evaluation_periods.find(
      (ep) => ep.selected,
    );

    const url = {
      pathname: `/relatorios/avaliacao-desempenho/${listId}`,
      search: `work_deal=${selectedWorkDeal.id}&evaluation_period=${selectedPeriod?.id}`,
    };

    if (onlyReceived) {
      url.search += '&recebidas=1';
    }

    history.push(url);
  };

  return (
    <Box id="container">
      <SectionHeader
        title="Avaliação de Desempenho"
        pagename="Lista de Avaliações"
      >
        {!onlyReceived ? (
          <Button
            aria-label="help"
            onClick={() => {
              setEnableTourEvaluator(true);
            }}
            variant="outline"
            colorScheme="blue"
          >
            <FiHelpCircle />
            <Text ml={2}>Ajuda</Text>
          </Button>
        ) : (
          <Button
            aria-label="help"
            onClick={() => {
              setEnableTourRatedUser(true);
            }}
            variant="outline"
            colorScheme="blue"
          >
            <FiHelpCircle />
            <Text ml={2}>Ajuda</Text>
          </Button>
        )}
        {/* {canCreatePerformanceReviews && (
          <Link to="/relatorios/avaliacao-desempenho/cadastrar">
            <Button
              leftIcon={<FiPlus />}
              colorScheme="blue"
              variant="solid"
              mr={3}
            >
              Cadastrar
            </Button>
          </Link>
        )} */}
      </SectionHeader>
      <Box mr={4} mb={4}>
        <Form onSubmit={() => ''}>
          <Box id="period">
            <Flex mb={4}>
              <Text as="b" mr={3}>
                Período:
              </Text>
              {loadingDate ? (
                <Skeleton width="200px" height="20px" />
              ) : (
                <>
                  {selectedWorkDeal?.id && (
                    <Text>
                      {selectedWorkDeal?.data_inicio} até{' '}
                      {selectedWorkDeal?.data_fim}
                    </Text>
                  )}
                </>
              )}
            </Flex>
            {/* ---------------- temporario --------------- */}
            {!loading && !loadingDate ? (
              <>
                <Box maxWidth="400px">
                  <SelectChakra
                    name="work_deal"
                    options={workDeals.map((wd) => {
                      return {
                        ...wd,
                        value: wd.id,
                        label: wd.nome,
                        disabled: wd.id > currentWorkDealId,
                      };
                    })}
                    onChange={(e) => {
                      handleSelectWorkDeal(e.target.value);
                    }}
                    defaultValue={selectedWorkDeal?.id?.toString()}
                  />
                </Box>
                <Wrap spacing={2}>
                  {selectedWorkDeal?.id &&
                    !!selectedWorkDeal.evaluation_periods.length &&
                    selectedWorkDeal.evaluation_periods?.map((period) => (
                      <WrapItem width={['full', 'auto']} key={period.id}>
                        <Button
                          width={['full', 'auto']}
                          colorScheme={period.selected ? 'blue' : 'gray'}
                          name="evaluation_period"
                          value={period.id}
                          onClick={(e) => {
                            // usar o target apenas não encontra a prop button
                            handleSelectEvaluationPeriod(e.currentTarget.value);
                          }}
                          // disabled={!period.show}
                        >
                          {`${period.start_date} até ${period.end_date}`}
                        </Button>
                      </WrapItem>
                    ))}
                </Wrap>
              </>
            ) : (
              <Skeleton maxWidth="400px" height="48px" />
            )}
          </Box>
        </Form>
        <Box id="team" mt={4}>
          <Flex justifyContent="space-between">
            <Text mr={8} fontSize="3xl">
              {onlyReceived ? 'Minhas avaliações' : 'Avaliações da Equipe'}
            </Text>
            {/* {!loading &&
              !onlyReceived &&
              !personalEvaluations.length &&
              canCreatePerformanceReviews &&
              selectedWorkDeal.id === currentWorkDealId &&
              !!selectedEvaluationPeriod?.active && (
                <Button colorScheme="blue" onClick={onOpenGenerateEvaluations}>
                  <Flex alignItems="center">
                    <FiPlay />
                    <Text ml={2}>Gerar avaliações</Text>
                  </Flex>
                </Button>
              )} */}
          </Flex>

          {loading ? (
            <Loading />
          ) : (
            <>
              {personalEvaluations?.length > 0 ? (
                <Table
                  theadData={tableTitles}
                  loading={loading}
                  /* pagination={{
                current_page: listAnnotation.current_page,
                last_page: listAnnotation.last_page,
                per_page: listAnnotation.per_page,
                to: listAnnotation.to,
                total: listAnnotation.total,
              }}
              newPage={(pg) => setPage(pg)} */
                >
                  {personalEvaluations.map((list) => {
                    const leaderEvaluator = handleFindLeaderEvaluator(
                      list.evaluator_users,
                    );

                    return (
                      <Box
                        as="tr"
                        key={String(list.id)}
                        _hover={{ bg: 'gray.50' }}
                      >
                        <Box
                          as="td"
                          py={1}
                          textAlign="center"
                          fontWeight="bold"
                          width="30px"
                        >
                          {list?.id || '-'}
                        </Box>
                        <Box as="td" py={1}>
                          <Tooltip
                            label={leaderEvaluator?.name}
                            aria-label="Avaliador"
                          >
                            <Box>
                              <Text
                                color={
                                  leaderEvaluator?.id === Number(user.id)
                                    ? '#0d5df1 !important'
                                    : '#000'
                                }
                              >
                                {leaderEvaluator?.name}
                              </Text>
                            </Box>
                          </Tooltip>
                        </Box>
                        <Box as="td" py={1}>
                          <Tooltip
                            label={list.rated_user.name}
                            aria-label="Avaliado"
                          >
                            <Text
                              color={
                                list.rated_user.id === Number(user.id)
                                  ? '#0d5df1 !important'
                                  : '#000'
                              }
                            >
                              {list.rated_user.name}
                            </Text>
                          </Tooltip>
                        </Box>
                        <Box as="td" py={1}>
                          <Text>{list.rated_user.responsibility?.slug}</Text>
                        </Box>
                        {!onlyReceived && (
                          <Box as="td" py={1}>
                            <Text ml={8}>
                              {`${list.count_published_personal_notes}/${list.count_personal_notes}`}
                            </Text>
                          </Box>
                        )}

                        <Box as="td" py={1}>
                          <Text ml={8}>{`${list.count_general_notes}`}</Text>
                        </Box>
                        <Box as="td" py={1}>
                          {list.published_at ? (
                            <Badge colorScheme="green">Publicado</Badge>
                          ) : (
                            <Badge colorScheme="blue">
                              Em periodo avaliativo
                            </Badge>
                          )}
                        </Box>
                        <Box as="td" py={1}>
                          <Text as="b">
                            {list.published_at
                              ? format(
                                  parseISO(list.published_at),
                                  "dd/MM/yy 'às' HH:mm",
                                )
                              : '--'}
                          </Text>
                        </Box>
                        <Box as="td" py={1}>
                          <Flex>
                            <IconButton
                              aria-label="Visualizar"
                              icon={<FiEye />}
                              variant="ghost"
                              bg="blue.50"
                              ml={[0, 5]}
                              colorScheme="blue"
                              textAlign="center"
                              disabled={!handleShowActions()}
                              onClick={() => handlePerformanceReview(list.id)}
                            />
                            {/* {canCreatePerformanceReviews && (
                      <IconButton
                        aria-label="Deletar"
                        icon={<FiTrash />}
                        variant="ghost"
                        bg="red.50"
                        ml={[0, 2]}
                        colorScheme="red"
                        onClick={() => {
                          onOpen();
                          setSelectDeleteID(list.id);
                        }}
                        textAlign="center"
                      />
                    )} */}
                          </Flex>
                        </Box>
                      </Box>
                    );
                  })}
                </Table>
              ) : (
                <Alert status="info">
                  <AlertIcon />
                  Você não possui nenhuma avaliação.
                </Alert>
              )}
            </>
          )}
        </Box>
      </Box>

      <Box id="extras">
        {!onlyReceived && canCreatePerformanceReviews && (
          <>
            <Flex mt={10} mb={5} alignItems="center">
              <Text mr={3} fontSize="3xl">
                Anotações Extras
              </Text>
              {selectedWorkDeal.id === currentWorkDealId &&
                !!selectedEvaluationPeriod?.active && (
                  <ButtonIcon icon="plus" onClick={onOpenSelect} />
                )}
            </Flex>

            {!loading && !extraEvaluations?.length ? (
              <Alert status="info">
                <AlertIcon />
                Nenhuma anotação extra incluída
              </Alert>
            ) : (
              <Table theadData={tableTitles} loading={loading}>
                {extraEvaluations.map((list) => {
                  const leaderEvalutor = handleFindLeaderEvaluator(
                    list.evaluator_users,
                  );

                  return (
                    <Box
                      as="tr"
                      key={String(list.id)}
                      _hover={{ bg: 'gray.50' }}
                    >
                      <Box
                        as="td"
                        py={1}
                        textAlign="center"
                        fontWeight="bold"
                        width="30px"
                      >
                        {list?.id || '-'}
                      </Box>
                      <Box as="td" py={1}>
                        <Tooltip
                          label={leaderEvalutor?.name}
                          aria-label="Avaliador"
                        >
                          <Box>
                            <Text
                              color={
                                leaderEvalutor?.id === Number(user.id)
                                  ? '#0d5df1 !important'
                                  : '#000'
                              }
                            >
                              {leaderEvalutor?.name}
                            </Text>
                          </Box>
                        </Tooltip>
                      </Box>
                      <Box as="td" py={1}>
                        <Tooltip
                          label={list.rated_user.name}
                          aria-label="Avaliado"
                        >
                          <Text
                            color={
                              list.rated_user.id === Number(user.id)
                                ? '#0d5df1 !important'
                                : '#000'
                            }
                          >
                            {list.rated_user.name}
                          </Text>
                        </Tooltip>
                      </Box>
                      <Box as="td" py={1}>
                        <Text>{list.rated_user.responsibility?.slug}</Text>
                      </Box>
                      <Box as="td" py={1}>
                        <Text textAlign="center">{`${list.count_published_personal_notes}/${list.count_personal_notes}`}</Text>
                      </Box>
                      <Box as="td" py={1}>
                        <Text textAlign="center">{`${list.count_general_notes}`}</Text>
                      </Box>
                      <Box as="td" py={1}>
                        {list.published_at ? (
                          <Badge colorScheme="green">Publicado</Badge>
                        ) : (
                          <Badge colorScheme="blue">
                            Em periodo avaliativo
                          </Badge>
                        )}
                      </Box>
                      <Box as="td" py={1}>
                        <Text as="b">
                          {list.published_at
                            ? format(
                                parseISO(list.published_at),
                                "dd/MM/yy 'às' HH:mm",
                              )
                            : '--'}
                        </Text>
                      </Box>
                      <Box as="td" py={1}>
                        <Flex>
                          <IconButton
                            aria-label="Visualizar"
                            icon={<FiEye />}
                            variant="ghost"
                            bg="blue.50"
                            ml={[0, 5]}
                            colorScheme="blue"
                            textAlign="center"
                            onClick={() => handlePerformanceReview(list.id)}
                          />
                        </Flex>
                      </Box>
                    </Box>
                  );
                })}
              </Table>
            )}
          </>
        )}
      </Box>

      {/* //////////////   Modal Criar   \\\\\\\\\\\\\ */}

      {canCreatePerformanceReviews && (
        <ModalChakra
          title="Criar Avaliação"
          onClose={onCloseSelect}
          isOpen={isOpenSelect}
          modalFooter={false}
        >
          <Form ref={formRef} onSubmit={handleSubmit}>
            <SelectChakra
              placeholder={
                loadingUsers
                  ? 'Carregando...'
                  : 'Escolha o funcionario que deseja avaliar'
              }
              isInline
              name="rated_user_id"
              options={listAll?.filter(
                (us) =>
                  us.id !== Number(user.id) &&
                  (!listAnnotation?.length ||
                    (!!listAnnotation?.length &&
                      !listAnnotation?.find(
                        (ltanot) => ltanot.rated_user.id === us.id,
                      ))),
              )}
              disabled={loadingUsers}
            />
          </Form>
          <Flex mt="30px" justifyContent="end">
            <Button colorScheme="gray" onClick={() => onCloseSelect()} mr={4}>
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                formRef.current?.submitForm();
                onCloseSelect();
              }}
            >
              Criar
            </Button>
          </Flex>
        </ModalChakra>
      )}

      {/* //////////////   Modal Deletar   \\\\\\\\\\\\\ */}
      <ModalChakra
        title="Deletar Avaliação?"
        onClose={onClose}
        isOpen={isOpen}
        modalFooter={false}
      >
        <Text>Tem certeza que deseja Deletar essa avaliação?</Text>
        <Flex mt="30px" justifyContent="end">
          <Button colorScheme="gray" onClick={() => onClose()} mr={4}>
            Cancelar
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              handleDelete(selectDeleteID);
              onClose();
            }}
          >
            Deletar
          </Button>
        </Flex>
      </ModalChakra>

      {/* Necessário obter o objeto do período avaliativo */}
      {selectedEvaluationPeriod && (
        <AlertDialog
          title={`Gerar avaliações de desempenho para sua equipe no período avaliativo de ${selectedEvaluationPeriod.start_date} até ${selectedEvaluationPeriod.end_date}`}
          description="Antes de gerar as avaliações certifique-se que sua equipe já foi criada nos departamentos corretamente"
          isOpen={isOpenGenerateEvaluations}
          leastDestructiveRef={cancelRefGenerateEvaluations}
          onClose={onCloseGenerateEvaluations}
          onSubmit={() => handleGenerateEvaluations()}
          isLoading={loadingGenerateEvaluations}
          VariantColor="blue"
          submitButtonColor="blue.500"
        />
      )}

      {!loading && (
        <>
          {!onlyReceived ? (
            <StepsIntro
              enabled={!loading && !loadingDate && enableTourEvaluator}
              onExit={() => setEnableTourEvaluator(false)}
              steps={stepsEvaluator}
            />
          ) : (
            <StepsIntro
              enabled={!loading && !loadingDate && enableTourRatedUser}
              onExit={() => setEnableTourRatedUser(false)}
              steps={stepsRatedUser}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default PerformanceReview;
