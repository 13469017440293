import React, { useState, useEffect, useCallback, useRef } from 'react';
import Cards, { Focused } from 'react-credit-cards-2';
import { FiHelpCircle, FiShield, FiEdit, FiPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Flex,
  Button,
  Text,
  Heading,
  IconButton,
  Grid,
  Img,
  useToast,
  Skeleton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core/typings/types';
import { Form } from '@unform/web';
import { Divider } from 'antd';
import { format, parseISO, parse } from 'date-fns';
import { Step } from 'intro.js';
import * as Yup from 'yup';

import vectorSignatureComp from '~/assets/vectorSignatureEmpresa.svg';
import vectorSignature from '~/assets/vetorSignature.svg';
import { useAuth } from '~/hooks/auth';
import { HoverAvatar } from '~/modules/accessControl/pages/User/styles';
import { Subscriptions } from '~/modules/auth/pages/SignIn';
import { Company } from '~/modules/management/@types/management';
import PDFTermsOfService, {
  LicenseGroups,
} from '~/modules/management/components/PDFTermsOfService';
import AvatarCustom from '~/shared/components/AvatarCustom';
import { Label } from '~/shared/components/Input/styles';
import InputChakra from '~/shared/components/InputChakra';
import SelectChakra from '~/shared/components/InputChakra/SelectChakra';
import InputImage from '~/shared/components/InputImage';
import SectionHeader from '~/shared/components/SectionHeader';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';
import { competenceMask } from '~/utils/mask';

interface SubscriptionStatus {
  id: number;
  name: string;
  barte_value: string;
  created_at: string;
  updated_at: string;
}

interface Subscripti extends Subscriptions {
  company: Company;
  subscription_status: SubscriptionStatus;
}

interface NewCompany {
  address: string;
  cep: string;
  city: string;
  cnpj: string;
  cvc: string;
  email: string;
  expiry: string;
  name: string;
  number: string;
  phone: string;
  terms_of_service: boolean;
  uf: string;
}

interface CreditCardPreviweProps {
  number: string;
  expiry: string;
  cvc: string;
  name: string;
}

interface PromoCode {
  id: number;
  code: string;
  discount_rate: string;
  description: string;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
}

interface Plan {
  id: number;
  name: string;
  description: string;
  min_collaborators: number;
  max_collaborators: number;
  price: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  calculated_price: string;
  uuid: string;
}

interface Period {
  id: number;
  period: string;
  discount_rate: number | null;
  created_at: string;
  updated_at: string;
  license_groups: LicenseGroups[];
  value_per_month: string;
  total_discount: string;
  original_value: string;
  total_value: string;
  original_total_value: string;
  installments: number;
}

interface Signature {
  plan: Plan;
  periods: Period[];
  promotional_code?: PromoCode;
}

interface SubscriptionRepresentative {
  id: number;
  user_id: number;
  cpf: string;
  rg: string;
  phone: string;
  created_at: string;
  updated_at: string;
}

interface SubscriptionBuyer {
  id: number;
  uuid_buyer: string;
  company_id: number;
  email: string;
  phone: string;
  cep: string;
  uf: string;
  city: string;
  address: string;
  created_at: string;
  updated_at: string;
}

interface CompanySubscription {
  id: number;
  id_alterdata: number;
  cnpj: string;
  email: string;
  empresa_grupo_id: string;
  created_at: string;
  updated_at: string;
  name: string;
  company_name: string;
  subscription_buyer: SubscriptionBuyer;
}

const SignatureCreate: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formRefNewComp = useRef<FormHandles>(null);
  const formRefCompData = useRef<FormHandles>(null);
  const formRefSimulate = useRef<FormHandles>(null);
  const addToast = useToast();

  const [step, setStep] = useState(0);
  const [typeSubscription, setTypeSubscription] = useState(0);

  // const [subscriptions, setSubscriptions] = useState<Subscripti[]>([]);
  const [
    subscriptionsRepresentative,
    setSubscriptionsRepresentative,
  ] = useState<SubscriptionRepresentative>();
  const [
    companySubscription,
    setCompanySubscription,
  ] = useState<CompanySubscription>();

  // const [price, setPrice] = useState<Signature>();

  const [periodPrice, setPeriodPrice] = useState<Period>();
  const [plan, setPlan] = useState<Plan>();
  const [promoCode, setPromoCode] = useState<PromoCode>();
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [code, setCode] = useState(false);
  const [amount, setAmount] = useState('');

  const [loadingValue, setLoadingValue] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(false);
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);

  const [companyData, setCompanyData] = useState<NewCompany>();

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [cardCVC, setCardCVC] = useState('');
  const [focus, setFocus] = useState<Focused>('name');
  const [creditCard, setCreditCard] = useState<CreditCardPreviweProps>({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
  });

  const [contractBlob, setContractBlob] = useState<Blob>();

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const states = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AP', value: 'AP' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MS', value: 'MS' },
    { label: 'MT', value: 'MT' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' },
  ];

  useEffect(() => {
    // api.get<Subscripti[]>('subscriptions').then((response) => {
    //   const { data } = response;
    //   setSubscriptions(data);
    // });

    api.get(`subscriptions/representative/${user.id}`).then((response) => {
      const { data } = response;
      setSubscriptionsRepresentative(data);
    });

    api
      .get<CompanySubscription>(`companies/${user.company_id}`)
      .then((response) => {
        const { data } = response;
        setCompanySubscription(data);
      });
  }, [user.id, user.company_id]);

  const handleTypePayment = useCallback((idPeriod: number) => {
    // caso for 1 ou 2 vai printar Semester
    switch (idPeriod) {
      case 1:
      case 2:
        return 'SEMESTER';
      case 3:
        return 'YEARLY';

      default:
        return 'SEMESTER';
    }
  }, []);

  const redirectToUser = useCallback(
    (signatureId: number) => {
      history.push({
        pathname: `/assinaturas/${signatureId}/usuarios`,
      });
    },
    [history],
  );

  const redirectToSignature = useCallback(() => {
    history.push({
      pathname: `/assinaturas`,
    });
  }, [history]);

  const handlePlanSimulate = useCallback(
    async (dataForm) => {
      setLoadingValue(true);
      setAmount(dataForm.license);

      try {
        formRefSimulate.current?.setErrors({});

        const schema = Yup.object().shape({
          period: Yup.string().required('Selecione um periodo'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const response = await api.get<Signature>(
          'subscriptions/plans/simulate',
          {
            params: {
              qty_collaborators: dataForm.license,
              promotional_code: dataForm?.code || '',
            },
          },
        );

        const { periods, plan: planSimulate, promotional_code } = response.data;

        setPromoCode(promotional_code);
        setPlan(planSimulate);
        setPeriodPrice(
          periods.find((period: any) => {
            // console.log(period, period.id === Number(dataForm.period));
            return period.id === Number(dataForm.period);
          }),
        );
        setCode(!!dataForm.code);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefSimulate.current?.setErrors(errors);
          return;
        }
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description: 'Codigo invalido',
        });
      } finally {
        setLoadingValue(false);
      }
    },
    [addToast],
  );

  const submitSignature = useCallback(
    async (data: CreditCardPreviweProps) => {
      setLoadingSignature(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome presente no cartão.'),
          number: Yup.string().required('Número do cartão é obrigatório.'),
          cvc: Yup.string().required('CVV do cartão é obrigatório.'),
          expiry: Yup.string().required(
            'Data de validade do cartão é obrigatório.',
          ),
          terms_of_service: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const expiry = data.expiry.split('/');

        if (expiry.length < 2 || Number(expiry[0]) > 12) {
          throw new Error('Verifique a data de vencimento novamente');
        }

        const formatDateExpiry = format(
          parse(data.expiry, 'MM/yy', new Date()),
          'MM/yyyy',
        );

        const dataFile = new FormData();

        if (!companySubscription) return;
        if (!periodPrice) return;
        if (!plan) return;

        dataFile.append('company[cnpj]', companySubscription?.cnpj);
        dataFile.append(
          'company[company_name]',
          companySubscription.company_name,
        );
        dataFile.append('company[name]', companySubscription.name);
        dataFile.append(
          'company[email]',
          companySubscription.subscription_buyer.email,
        );
        dataFile.append(
          'company[phone]',
          companySubscription.subscription_buyer.phone,
        );
        dataFile.append(
          'company[cep]',
          companySubscription.subscription_buyer.cep,
        );
        dataFile.append(
          'company[uf]',
          companySubscription.subscription_buyer.uf,
        );
        dataFile.append(
          'company[city]',
          companySubscription.subscription_buyer.city,
        );
        dataFile.append(
          'company[address]',
          companySubscription.subscription_buyer.address,
        );
        dataFile.append('representative[name]', user.name);
        dataFile.append('representative[email]', user.email);
        // dataFile.append('subscription[uuid_barte]', companySubscription.subscription_buyer.uuid_buyer); COMENTANDO POIS ESSE DADO ESTA VINDO DO BACK-END
        dataFile.append(
          'subscription[subscription_plan_id]',
          plan.id.toString(),
        );
        dataFile.append(
          'subscription[subscription_period_id]',
          periodPrice.id.toString(),
        );
        dataFile.append(
          'subscription[additional_discount]',
          promoCode ? promoCode.discount_rate : '',
        );
        dataFile.append('subscription[amount]', amount.toString());
        dataFile.append('subscription[contract]', contractBlob || '');
        dataFile.append(
          'subscription[buyer_id]',
          companySubscription.subscription_buyer.id.toString(),
        );
        dataFile.append(
          'uuidBuyer',
          companySubscription?.subscription_buyer.uuid_buyer,
        );
        dataFile.append('uuidPlan', plan?.uuid);
        dataFile.append('basicValue[type]', selectedPeriod);
        dataFile.append(
          'basicValue[valuePerMonth]',
          (periodPrice ? Number(periodPrice?.original_total_value) : 0).toFixed(
            2,
          ),
        );
        dataFile.append('additionalValue[value]', '1');
        dataFile.append('additionalValue[installments]', '1');
        dataFile.append('payment[method]', 'CREDIT_CARD');
        dataFile.append(
          'payment[card][holderName]',
          data.name.toLocaleUpperCase(),
        );
        dataFile.append('payment[card][number]', data.number);
        dataFile.append('payment[card][expiration]', formatDateExpiry);
        dataFile.append('payment[card][cvv]', data.cvc);
        dataFile.append(
          'payment[fraudData][document]',
          companySubscription?.cnpj,
        );
        dataFile.append('payment[fraudData][name]', companySubscription?.name);
        dataFile.append(
          'payment[fraudData][email]',
          companySubscription?.subscription_buyer?.email,
        );
        dataFile.append(
          'payment[fraudData][phone]',
          companySubscription?.subscription_buyer?.phone,
        );
        dataFile.append('payment[fraudData][billingAddress][country]', 'BR');
        dataFile.append(
          'payment[fraudData][billingAddress][state]',
          companySubscription?.subscription_buyer?.city,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][city]',
          companySubscription?.subscription_buyer?.city,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][district]',
          companySubscription?.subscription_buyer?.uf,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][street]',
          companySubscription?.subscription_buyer?.address,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][zipCode]',
          companySubscription?.subscription_buyer?.cep,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][number]',
          companySubscription?.subscription_buyer?.address,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][complement]',
          companySubscription?.subscription_buyer?.address,
        );
        dataFile.append('startDate', currentDate);

        const response = await api.post('subscriptions', dataFile);

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Atividade finalizada!',
          description: 'Pagamento realizado com sucesso!',
          status: 'success',
        });
        redirectToUser(response.data.id);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            error.response?.data?.error ||
            'Erro nos dados do cartão, tente novamente',
        });
      } finally {
        setLoadingSignature(false);
      }
    },
    [
      addToast,
      periodPrice,
      amount,
      contractBlob,
      companySubscription,
      plan,
      selectedPeriod,
      promoCode,
      user.email,
      user.name,
      currentDate,
      redirectToUser,
    ],
  );

  const submitCompanyData = useCallback(
    async (data: NewCompany) => {
      setLoadingCompanyData(true);
      try {
        formRefCompData.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome da empresa.'),
          email: Yup.string().required('Digite o email da empresa.'),
          phone: Yup.string().required('Digite seu Telefone.'),
          cnpj: Yup.string().required('Digite o CNPJ da empresa.'),
          cep: Yup.string().required('Digite o CEP da empresa.'),
          uf: Yup.string().required('Selecione o UF da empresa.'),
          city: Yup.string().required('Digite a cidade da empresa.'),
          address: Yup.string().required('Digite o endereço da empresa.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setCompanyData({
          ...data,
          cep: data.cep.replace(/\D+/g, ''),
          cnpj: data.cnpj.replace(/\D+/g, ''),
          phone: data.phone.replace(/\D+/g, ''),
        });

        setStep(step + 1);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefCompData.current?.setErrors(errors);
        }
      } finally {
        setLoadingCompanyData(false);
      }
    },
    [step],
  );

  const submitNewCompany = useCallback(
    async (data: CreditCardPreviweProps) => {
      setLoadingSignature(true);

      try {
        formRefNewComp.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome presente no cartão.'),
          number: Yup.string().required('Número do cartão é obrigatório.'),
          cvc: Yup.string().required('CVV do cartão é obrigatório.'),
          expiry: Yup.string().required(
            'Data de validade do cartão é obrigatório.',
          ),
          terms_of_service: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const expiry = data.expiry.split('/');

        if (expiry.length < 2 || Number(expiry[0]) > 12) {
          throw new Error('Verifique a data de vencimento novamente');
        }

        const formatDateExpiry = format(
          parse(data.expiry, 'MM/yy', new Date()),
          'MM/yyyy',
        );

        const dataFile = new FormData();

        if (!companySubscription) return;
        if (!periodPrice) return;
        if (!plan) return;
        if (!companyData) return;

        dataFile.append('company[cnpj]', companyData?.cnpj);
        dataFile.append('company[company_name]', companyData.name);
        dataFile.append('company[name]', companyData.name);
        dataFile.append('company[email]', companyData.email);
        dataFile.append('company[phone]', companyData.phone);
        dataFile.append('company[cep]', companyData.cep);
        dataFile.append('company[uf]', companyData.uf);
        dataFile.append('company[city]', companyData.city);
        dataFile.append('company[address]', companyData.address);
        dataFile.append('representative[name]', user.name);
        dataFile.append('representative[email]', user.email);
        // dataFile.append('subscription[uuid_barte]', companySubscription.subscription_buyer.uuid_buyer); COMENTANDO POIS ESSE DADO ESTA VINDO DO BACK-END
        dataFile.append(
          'subscription[subscription_plan_id]',
          plan.id.toString(),
        );
        dataFile.append(
          'subscription[subscription_period_id]',
          periodPrice.id.toString(),
        );
        dataFile.append(
          'subscription[additional_discount]',
          promoCode ? promoCode.discount_rate : '',
        );
        dataFile.append('subscription[amount]', amount.toString());
        dataFile.append('subscription[contract]', contractBlob || '');
        dataFile.append(
          'subscription[buyer_id]',
          companySubscription.subscription_buyer.id.toString(),
        );
        dataFile.append(
          'uuidBuyer',
          companySubscription?.subscription_buyer.uuid_buyer,
        );
        dataFile.append('uuidPlan', plan?.uuid);
        dataFile.append('basicValue[type]', selectedPeriod);
        dataFile.append(
          'basicValue[valuePerMonth]',
          (periodPrice ? Number(periodPrice?.original_total_value) : 0).toFixed(
            2,
          ),
        );
        dataFile.append('additionalValue[value]', '1');
        dataFile.append('additionalValue[installments]', '1');
        dataFile.append('payment[method]', 'CREDIT_CARD');
        dataFile.append(
          'payment[card][holderName]',
          data.name.toLocaleUpperCase(),
        );
        dataFile.append('payment[card][number]', data.number);
        dataFile.append('payment[card][expiration]', formatDateExpiry);
        dataFile.append('payment[card][cvv]', data.cvc);
        dataFile.append('payment[fraudData][document]', companyData.cnpj);
        dataFile.append('payment[fraudData][name]', data?.name);
        dataFile.append('payment[fraudData][email]', companyData.email);
        dataFile.append('payment[fraudData][phone]', companyData.phone);
        dataFile.append('payment[fraudData][billingAddress][country]', 'BR');
        dataFile.append(
          'payment[fraudData][billingAddress][state]',
          companyData.city,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][city]',
          companyData.city,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][district]',
          companyData.uf,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][street]',
          companyData.address,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][zipCode]',
          companyData.cep,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][number]',
          companyData.address,
        );
        dataFile.append(
          'payment[fraudData][billingAddress][complement]',
          companyData.address,
        );
        dataFile.append('startDate', currentDate);
        dataFile.append('first_purchase', '1');
        dataFile.append('user_id', user.id);
        await api.post('subscriptions', dataFile);

        addToast({
          position: 'top-right',
          isClosable: true,
          title: 'Atividade finalizada!',
          description: 'Pagamento realizado com sucesso!',
          status: 'success',
        });
        redirectToSignature();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRefNewComp.current?.setErrors(errors);
          return;
        }
        addToast({
          position: 'top-right',
          isClosable: true,
          status: 'error',
          title: 'Ooops!!!',
          description:
            error.response?.data?.error ||
            'Erro nos dados do cartão, tente novamente',
        });
      } finally {
        setLoadingSignature(false);
      }
    },
    [
      user.id,
      addToast,
      periodPrice,
      amount,
      contractBlob,
      companySubscription,
      plan,
      selectedPeriod,
      promoCode,
      user.email,
      user.name,
      currentDate,
      redirectToSignature,
      companyData,
    ],
  );

  return (
    <Box>
      <SectionHeader
        goBackPage
        title="Controle de assinatura"
        pagename="Assinatura"
      />
      {step === 0 && (
        <>
          <Flex
            flexDir={['column', 'row']}
            justifyContent="center"
            height={['auto', '450px']}
            mt="40px"
          >
            <InputImage
              title="Adquirir novas Licenças"
              img={vectorSignature}
              htmlFor="signature"
              valueInput="1"
              selectedSubscription={() => {
                setTypeSubscription(1);
              }}
              selectedDoubleClick={() => setStep(step + 1)}
            />
            {/* <InputImage
              title="Cadastrar uma nova Empresa"
              img={vectorSignatureComp}
              htmlFor="company"
              valueInput="2"
              selectedSubscription={() => {
                setTypeSubscription(2);
              }}
              selectedDoubleClick={() => setStep(step + 1)}
            /> */}
          </Flex>
          <Flex justifyContent={step > 0 ? 'space-between' : 'end'} mt={4}>
            {step > 0 && (
              <Button onClick={() => setStep(step - 1)}>voltar</Button>
            )}
            <Button
              type="button"
              onClick={() => setStep(step + 1)}
              colorScheme="blue"
            >
              Prosseguir
            </Button>
          </Flex>
        </>
      )}
      {step === 1 && typeSubscription === 1 && (
        <>
          <Form
            onSubmit={(data) => handlePlanSimulate(data)}
            ref={formRefSimulate}
          >
            <Flex
              flexDir={['column', 'row']}
              justifyContent="space-around"
              alignItems="center"
              mb={5}
            >
              <Box width={['auto', '500px']}>
                <Flex alignItems="center">
                  <Text mb="8px" mr={3} fontSize="xl">
                    Número de Licenças:
                  </Text>
                  <InputChakra
                    name="license"
                    type="number"
                    mb="0px"
                    min={5}
                    // onChange={(value) =>
                    //   handlePlanSimulate(value.currentTarget.value)
                    // }
                    defaultValue={5}
                  />
                </Flex>
                <Flex alignItems="center">
                  <Text mb="8px" mr={3} fontSize="xl">
                    Periodo desejado:
                  </Text>
                  <SelectChakra
                    width="100%"
                    name="period"
                    mb="0px"
                    ml={6}
                    placeholder="Selecione um periodo"
                    options={[
                      { label: 'Semestral', value: 2 },
                      { label: 'Anual', value: 3 },
                    ]}
                    onChange={(value) => {
                      setSelectedPeriod(
                        handleTypePayment(Number(value.currentTarget.value)),
                      );
                    }}
                  />
                </Flex>
                <Flex alignItems="center">
                  <Text mb="8px" mr={3} fontSize="xl">
                    Codigo de Desconto:
                  </Text>
                  <InputChakra name="code" type="text" mb="0px" toUpperCase />
                </Flex>
                <Flex justifyContent={['center', 'end']} my={4}>
                  <Button
                    isLoading={loadingValue}
                    colorScheme="blue"
                    type="submit"
                  >
                    Simular Valor
                  </Button>
                </Flex>
              </Box>
              <Box width={['auto', '500px']} mx={6}>
                <Flex mb={3}>
                  <Text fontSize="2xl">Valor por Mês: </Text>
                  {!loadingValue ? (
                    <>
                      <Text fontSize="2xl" as={code ? 'del' : 'b'} ml={3}>
                        R$
                        {periodPrice?.original_value}
                      </Text>
                      {code && (
                        <Text fontSize="2xl" as="b" ml={3}>
                          R${periodPrice?.value_per_month}
                        </Text>
                      )}
                    </>
                  ) : (
                    <Skeleton w="150px" h="36px" ml={4} />
                  )}
                </Flex>

                <Flex alignItems="center">
                  <Text fontSize="2xl">Valor Total: </Text>
                  {!loadingValue ? (
                    <>
                      <Text fontSize="2xl" as={code ? 'del' : 'b'} ml={3}>
                        R$
                        {periodPrice?.original_total_value}
                      </Text>
                      {code && (
                        <Text fontSize="2xl" as="b" ml={3}>
                          R${periodPrice?.total_value}
                        </Text>
                      )}
                    </>
                  ) : (
                    <Skeleton w="150px" h="36px" ml={4} />
                  )}
                </Flex>
              </Box>
            </Flex>
          </Form>

          <Divider />
          <Form onSubmit={submitSignature} ref={formRef}>
            <Flex
              // width="100%"
              flexDir={['column', 'row']}
              justifyContent="space-around"
              alignItems="center"
            >
              <Box width={['auto', '500px']}>
                <InputChakra
                  toUpperCase
                  name="name"
                  label="Nome"
                  placeholder="Digite corretamente o nome escrito no cartão"
                  onChangeCustom={(value) => setCardName(value)}
                  autoFocus
                  onFocus={() => setFocus('name')}
                />
                <InputChakra
                  name="number"
                  label="Numero do cartão"
                  placeholder="Digite o numero do cartão corretamente"
                  maxLength={16}
                  onChange={(el) => setCardNumber(el.target.value)}
                  onFocus={() => setFocus('number')}
                  // type="number"
                />
                <Flex>
                  <InputChakra
                    name="cvc"
                    label="Codigo do cartão"
                    placeholder="Digite o codigo CVV"
                    maxLength={3}
                    onChange={(el) => setCardCVC(el.target.value)}
                    onFocus={() => setFocus('cvc')}
                    mr={4}
                  />
                  <InputChakra
                    name="expiry"
                    label="Vencimento do cartão"
                    placeholder="Digite a validade"
                    type="text"
                    mask="competence"
                    onChangeCustom={(value) => {
                      setCardExpiry(competenceMask(value));
                    }}
                    onFocus={() => setFocus('expiry')}
                  />
                </Flex>
                {!loadingValue &&
                  companySubscription &&
                  subscriptionsRepresentative &&
                  periodPrice && (
                    <Flex>
                      <PDFTermsOfService
                        address={companySubscription.subscription_buyer.address}
                        cel={companySubscription.subscription_buyer.phone}
                        cep={companySubscription.subscription_buyer.cep}
                        cnpj={companySubscription.cnpj}
                        cpf={subscriptionsRepresentative.cpf}
                        name={companySubscription.company_name}
                        email={companySubscription.subscription_buyer.email}
                        emailCompany={
                          companySubscription.subscription_buyer.email
                        }
                        companyName={companySubscription.company_name}
                        installments={periodPrice?.installments}
                        license={amount}
                        licenseAmountTotal={periodPrice.license_groups}
                        period={periodPrice.period}
                        totalValue={periodPrice.total_value}
                        valuePerMonth={periodPrice.value_per_month}
                        discount_rate={promoCode?.discount_rate}
                        saveBlob={(file) => setContractBlob(file)}
                      />
                    </Flex>
                  )}
                {loadingValue && <Box mt="48px" />}
              </Box>
              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                width={['auto', '500px']}
                mx={6}
              >
                {creditCard && (
                  <Cards
                    number={cardNumber}
                    expiry={cardExpiry}
                    cvc={cardCVC}
                    name={cardName}
                    placeholders={{ name: 'Seu Nome' }}
                    focused={focus}
                  />
                )}
              </Flex>
            </Flex>
            <Flex justifyContent={step > 0 ? 'space-between' : 'end'} mt={4}>
              {step > 0 && (
                <Button onClick={() => setStep(step - 1)}>voltar</Button>
              )}
              <Button
                type={step === 1 ? 'submit' : 'button'}
                // onClick={() => setStep(step + 1)}
                colorScheme="blue"
                isLoading={loadingSignature}
              >
                {step === 1 ? 'Comprar' : 'Prosseguir'}
              </Button>
            </Flex>
          </Form>
        </>
      )}
      {step === 1 && typeSubscription === 2 && (
        <>
          <Form onSubmit={(data) => handlePlanSimulate(data)}>
            <Flex
              flexDir={['column', 'row']}
              justifyContent="space-around"
              mb={5}
            >
              <Box width={['auto', '500px']}>
                <Flex alignItems="center">
                  <Text mb="8px" mr={3} fontSize="xl">
                    Número de Licenças:
                  </Text>
                  <InputChakra
                    name="license"
                    type="number"
                    mb="0px"
                    min={5}
                    // onChange={(value) =>
                    //   handlePlanSimulate(value.currentTarget.value)
                    // }
                    defaultValue={5}
                  />
                </Flex>
                <Flex alignItems="center">
                  <Text mb="8px" mr={3} fontSize="xl">
                    Periodo desejado:
                  </Text>
                  <SelectChakra
                    width="100%"
                    name="period"
                    mb="0px"
                    ml={6}
                    placeholder="Selecione um periodo"
                    options={[
                      { label: 'Semestral', value: 2 },
                      { label: 'Anual', value: 3 },
                    ]}
                    onChange={(value) => {
                      setSelectedPeriod(value.currentTarget.value);
                    }}
                  />
                </Flex>
                <Flex alignItems="center">
                  <Text mb="8px" mr={3} fontSize="xl">
                    Codigo de Desconto:
                  </Text>
                  <InputChakra
                    name="code"
                    type="text"
                    mb="0px"
                    toUpperCase
                    // onChange={(e) => setCode(e.currentTarget.value)}
                  />
                </Flex>
                <Flex justifyContent={['center', 'end']} my={4}>
                  <Button
                    isLoading={loadingValue}
                    colorScheme="blue"
                    type="submit"
                  >
                    Simular Valor
                  </Button>
                </Flex>
              </Box>
              <Box width={['auto', '500px']} mx={6}>
                <Flex mb={3}>
                  <Text fontSize="2xl">Valor por Mês: </Text>
                  <Text fontSize="2xl" as={code ? 'del' : 'b'} ml={3}>
                    R$
                    {periodPrice?.original_value}
                  </Text>
                  {!loadingValue && code && (
                    <Text fontSize="2xl" as="b" ml={3}>
                      R${periodPrice?.value_per_month}
                    </Text>
                  )}
                </Flex>

                <Flex>
                  <Text fontSize="2xl">Valor Total: </Text>
                  <Text fontSize="2xl" as={code ? 'del' : 'b'} ml={3}>
                    R$
                    {periodPrice?.original_total_value}
                  </Text>
                  {!loadingValue && code && (
                    <Text fontSize="2xl" as="b" ml={3}>
                      R${periodPrice?.total_value}
                    </Text>
                  )}
                </Flex>
              </Box>
            </Flex>
          </Form>

          <Divider />

          <Form onSubmit={submitCompanyData} ref={formRefCompData}>
            <Flex
              // width="100%"
              justifyContent="space-around"
              flexDir={['column', 'row']}
            >
              <Box width={['auto', '500px']}>
                <Text fontSize="xl">Dados da Empresa</Text>
                <InputChakra
                  name="name"
                  label="Nome"
                  placeholder="Informe o nome"
                  id="companyName"
                />

                <InputChakra
                  name="email"
                  label="Email"
                  placeholder="Informe o email"
                  id="companyEmail"
                />

                <InputChakra
                  name="phone"
                  label="Telefone"
                  placeholder="Informe o telefone"
                  id="companyPhone"
                  mask="phone"
                />

                <InputChakra
                  name="cnpj"
                  label="CNPJ"
                  placeholder="Informe o CNPJ"
                  id="cnpj"
                  mask="cnpj"
                />
                <InputChakra
                  name="cep"
                  label="CEP"
                  placeholder="Informe seu CEP"
                  id="cep"
                  // onBlur={() => getCompanyCep(values, setValues)}
                  mask="cep"
                />
                <SelectChakra
                  width="100%"
                  name="uf"
                  id="companyUf"
                  label="UF"
                  placeholder="Selecione o estado"
                  options={states.map((state) => ({
                    label: state.label,
                    value: state.value,
                  }))}
                />
                <InputChakra
                  name="city"
                  label="Cidade"
                  placeholder="Informe a cidade"
                  id="companyCity"
                  // disabled={loadingCompanyCep}
                />
                <InputChakra
                  name="address"
                  label="Endereço"
                  placeholder="Informe o endereço"
                  id="companyAddress"
                  // disabled={loadingCompanyCep}
                />
              </Box>
            </Flex>
            <Flex justifyContent={step > 0 ? 'space-between' : 'end'} mt={4}>
              {step > 0 && (
                <Button onClick={() => setStep(step - 1)}>voltar</Button>
              )}
              <Button type="submit" colorScheme="blue">
                Prosseguir
              </Button>
            </Flex>
          </Form>
        </>
      )}
      {step === 2 && (
        <Flex justifyContent="center">
          <Form onSubmit={submitNewCompany} ref={formRefNewComp}>
            <Flex
              flexDir="column"
              justifyContent="center"
              width={['auto', '500px']}
              mx={6}
            >
              <Text fontSize="xl">Dados do Cartão</Text>
              <InputChakra
                toUpperCase
                name="name"
                label="Nome"
                placeholder="Digite corretamente o nome escrito no cartão"
                onChange={(el) => setCardName(el.target.value)}
                autoFocus
                onFocus={() => setFocus('name')}
              />
              <InputChakra
                name="number"
                label="Numero do cartão"
                placeholder="Digite o numero do cartão corretamente"
                maxLength={16}
                onChange={(el) => setCardNumber(el.target.value)}
                onFocus={() => setFocus('number')}
                // type="number"
              />
              <InputChakra
                name="cvc"
                label="Codigo do cartão"
                placeholder="Digite o codigo CVV do cartão "
                maxLength={3}
                onChange={(el) => setCardCVC(el.target.value)}
                onFocus={() => setFocus('cvc')}
              />
              <InputChakra
                name="expiry"
                label="Vencimento do cartão"
                placeholder="Digite a validade"
                type="text"
                mask="competence"
                onChangeCustom={(value) => {
                  setCardExpiry(competenceMask(value));
                }}
                onFocus={() => setFocus('expiry')}
              />
              {!loadingValue &&
                companySubscription &&
                subscriptionsRepresentative &&
                periodPrice &&
                companyData && (
                  <Flex>
                    <PDFTermsOfService
                      address={companySubscription.subscription_buyer.address}
                      cel={companySubscription.subscription_buyer.phone}
                      cep={companySubscription.subscription_buyer.cep}
                      cnpj={companyData.cnpj}
                      cpf={subscriptionsRepresentative.cpf}
                      name={companySubscription.company_name}
                      email={companySubscription.subscription_buyer.email}
                      emailCompany={
                        companySubscription.subscription_buyer.email
                      }
                      companyName={companySubscription.company_name}
                      installments={periodPrice?.installments}
                      license={amount}
                      licenseAmountTotal={periodPrice.license_groups}
                      period={periodPrice.period}
                      totalValue={periodPrice.total_value}
                      valuePerMonth={periodPrice.value_per_month}
                      discount_rate={promoCode?.discount_rate}
                      saveBlob={(file) => setContractBlob(file)}
                    />
                  </Flex>
                )}
              {creditCard && (
                <Box>
                  <Cards
                    number={cardNumber}
                    expiry={cardExpiry}
                    cvc={cardCVC}
                    name={cardName}
                    placeholders={{ name: 'Seu Nome' }}
                    focused={focus}
                  />
                </Box>
              )}
              <Divider />
            </Flex>
            <Flex justifyContent={step > 0 ? 'space-between' : 'end'} mt={4}>
              {step > 0 && (
                <Button onClick={() => setStep(step - 1)}>voltar</Button>
              )}
              <Button type="submit" colorScheme="blue">
                Comprar
              </Button>
            </Flex>
          </Form>
        </Flex>
      )}
    </Box>
  );
};
export default SignatureCreate;
