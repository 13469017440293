import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

interface LabelCardStyleProps {
  isChecked: boolean;
}

export const InputCardStyle = styled.input`
  & + label div svg {
    display: none;
  }

  & + label div {
    border: 3px solid transparent;
  }
  & + label div:hover {
    border: 3px solid #0b51c5;
    height: 436px;
  }

  &:checked + label div {
    border: 3px solid #09cc1d;
    height: 436px;

    svg {
      display: block;
    }

    @media (max-width: 768px) {
      height: 100%;
      padding-bottom: 10px;
    }
  }

  /* & + div svg {
    color: red !important;
  }

  & + div {
    border: ${(props) => (props ? ' 3px solid #09CC1D' : '')};
  } */
`;
export const LabelCardStyle = styled.label<LabelCardStyleProps>`
  /* div {
    border: 3px solid transparent;
  }
  div:hover:not(input:checked) {
    border: 3px solid blue;
  }

  input:checked + svg {
    color: red !important;
  }

  div {
    border: ${(props) => (props.isChecked ? ' 3px solid #09CC1D' : '')};
  } */
`;
