import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FaBriefcase } from 'react-icons/fa';
import {
  FiChevronDown,
  FiPlus,
  FiShield,
  FiUser,
  FiLock,
  FiMail,
  FiFilter,
  FiSlash,
  FiFlag,
  FiPower,
  FiEdit,
  FiHelpCircle,
} from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  useToast,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Box,
  Grid,
  Tooltip,
  Flex,
  Badge,
  Stack,
  Text,
  Heading,
  IconButton,
  Alert,
  AlertIcon,
  Switch,
  FormLabel,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import { Step } from 'intro.js';
import queryString from 'query-string';
import * as Yup from 'yup';

import { Replace } from '~/helpers/Replace';
import { useAuth } from '~/hooks/auth';
import { Subscriptions } from '~/modules/auth/pages/SignIn';
import { Signature } from '~/modules/management/pages/Signature';
import AlertDialog from '~/shared/components/AlertDialog';
import AvatarCustom from '~/shared/components/AvatarCustom';
import Filter from '~/shared/components/Filter';
import FilterTags from '~/shared/components/FilterTags';
import Input from '~/shared/components/InputChakra';
import RadioChakra from '~/shared/components/InputChakra/RadioChakra';
import Select from '~/shared/components/InputChakra/SelectChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import Search from '~/shared/components/Search';
import SectionHeader from '~/shared/components/SectionHeader';
import StepsIntro from '~/shared/components/Tour/StepsIntro';
import api from '~/shared/services/api';
import getValidationErrors from '~/utils/getValidationErrors';

import { User as UserData } from '../../@types/user';
import { HoverAvatar, CardLicense } from './styles';

interface UserInterface extends Replace<UserData, { responsibility?: string }> {
  loadingToogle?: boolean;
  subscription?: Subscriptions;
}

export interface UserFormData {
  name: string;
  email: string;
  password?: string;
  password_confirmation?: string;
  responsibility: string;
  department: string;
  subscription_id?: string;
  send_email?: boolean;
  subscription?: Subscriptions[];
}

interface UserProps {
  data: UserInterface[];
  current_page: number;
  last_page: number;
  total: number;
}

interface DepartmentProps {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  company_id: number;
  responsible_id: number;
  superior_department_id: number;
  value: string;
  label: string;
}

export interface DepartmentSelect {
  value: string;
  label: string;
}

interface ResponsibilityProps {
  id: number;
  role_id: number;
  name: string;
  slug: string;
  description: string;
  company_id: number;
  created_at: string;
  updated_at: string;
  value: string;
  label: string;
}

export interface ResponsibilitySelect {
  value: string;
  label: string;
}

interface FilterUser {
  responsibility_id: string;
  is_active: string;
}

interface QueryParams {
  resp?: string;
  status?: string;
}

interface Params {
  idSignature?: string;
}

export interface FiltersProps {
  department: DepartmentProps[];
  responsibility: ResponsibilityProps[];
}

const User: React.FC = () => {
  const { idSignature } = useParams<Params>();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useMemo<QueryParams>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const { user } = useAuth();
  const addToast = useToast();
  const formRef = useRef<FormHandles>(null);
  const btnRef = useRef(null);
  const btnFilterRef = useRef(null);
  const cancelRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();

  const [toggleSendEmail, setToggleSendEmail] = useState(true);
  const [page, setPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [responsibilities, setResponsibilities] = useState<
    ResponsibilitySelect[]
  >([]);
  const [departments, setDepartments] = useState<DepartmentSelect[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingDataForm, setLoadingDataForm] = useState(true);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [unlinkUser, setUnlinkUser] = useState<number | null>(null);

  const [selectedStatus, setSelectedStatus] = useState(
    queryParams?.status ? queryParams?.status : '',
  );
  const [selectedResponsibility, setSelectedResponsibility] = useState(
    queryParams?.resp ? queryParams?.resp : '',
  );
  const [subscription, setSubscription] = useState<Signature>({} as Signature);
  const [usersFiltered, setUsersFiltered] = useState<FilterUser | undefined>({
    is_active: selectedStatus.toString(),
    responsibility_id: selectedResponsibility.toString(),
  });

  const [loadReload, setLoadReload] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');

  const [enableTourUser, setEnableTourUser] = useState(false);
  const [stepsUser, setStepsUser] = useState<Step[]>([
    {
      element: '#container',
      title: 'Controle de Usuários',
      intro:
        'Nessa página você acompanha e administra todos os funcionários com seus respectivos cargos.',
    },
    {
      element: '#createUser',
      title: 'Cadastrar Usuário',
      intro: 'Aqui você pode criar uma conta no sistema. clique no botão.',
      position: 'left',
    },
    {
      element: '#editUser1',
      title: 'Editar usuário',
      intro:
        'Aqui você pode alterar os dados do funcionario como por exemplo nome, senha, cargo e departamento. clique no botão.',
    },
    {
      element: '#BlockUser1',
      title: 'Bloquear funcionário',
      intro:
        'Aqui você pode bloquear o funcionário que deseja. clique no botão.',
    },
  ]);

  const status = [
    {
      value: '*',
      label: 'Todos',
      colorScheme: 'blue',
    },
    {
      value: '1',
      label: 'Ativo',
      colorScheme: 'green',
    },
    {
      value: '0',
      label: 'Bloqueado',
      colorScheme: 'red',
    },
  ];

  const handleFilter = useCallback(({ is_active, responsibility_id }) => {
    setUsersFiltered({
      is_active,
      responsibility_id,
    });
    /*  setSelectedStatus(is_active);
    setSelectedResponsibility(responsibility_id); */
  }, []);

  useEffect(() => {
    if (search) {
      setLoadingSearch(true);
    } else {
      setLoading(true);
    }

    const { CancelToken } = axios;
    const source = CancelToken.source();

    if (idSignature) {
      api.get(`subscriptions/${idSignature}`).then((response) => {
        const { data } = response;
        setSubscription(data);
      });
    }

    api
      .get<UserProps>('company/users', {
        params: {
          page: 1,
          responsibility_id: usersFiltered?.responsibility_id,
          is_active: usersFiltered?.is_active,
          search,
          subscription_id: idSignature,
          subscription_all: user.is_subscribed && !idSignature ? '1' : '0',
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setUsers(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setCountData(response.data.total);
        setPage(2);
      })
      .finally(() => {
        setLoading(false);
        setLoadingSearch(false);
      });

    return () => {
      /* Após chamar novamente a api, sem que se tenha resposta da primeira solicitação (pendente)
          o pedido anterior é cancelado e se realiza um novo pedido */
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [usersFiltered, search, loadReload, idSignature, user.is_subscribed]);

  useEffect(() => {
    setLoadingDataForm(true);

    api.get<FiltersProps>('users/filters').then((response) => {
      const { department, responsibility } = response.data;
      // console.log(department, responsibility);

      setResponsibilities([
        { label: 'Sem cargo', value: '0' },
        ...responsibility.map((item) => ({
          label: item.name,
          value: String(item.id),
        })),
      ]);

      setDepartments(
        department.map((item) => ({
          label: item.name,
          value: String(item.id),
        })),
      );
    });

    setLoadingDataForm(false);
  }, []);

  const handleNextPage = useCallback(() => {
    if (currentPage < lastPage) {
      setLoading(true);

      api
        .get<UserProps>('company/users', {
          params: {
            page,
            responsibility_id: usersFiltered?.responsibility_id,
            is_active: usersFiltered?.is_active,
            search,
          },
        })
        .then((response) => {
          setUsers([...users, ...response.data?.data]);
          setCurrentPage(response.data.current_page);

          setPage(page + 1);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [currentPage, lastPage, page, users, usersFiltered, search]);

  const handleToggleStatusUser = useCallback(
    async (user_id: number, department_id: number, is_active: boolean) => {
      setUsers((us) =>
        us.map((u) => {
          return u.id === user_id
            ? {
                ...u,
                loadingToogle: true,
              }
            : u;
        }),
      );

      /* Adicionar o departamento do usuário nessa rota */
      await api
        .post(`timeline/${is_active ? 'block' : 'active'}`, {
          user_id,
          department_id,
        })
        .then(() => {
          setUsers((state) =>
            state.map((userData) => {
              if (user_id === userData.id) {
                return {
                  ...userData,
                  is_active: !is_active,
                  loadingToogle: false,
                };
              }
              return userData;
            }),
          );

          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'success',
            title: 'Status do usuário alterado com sucesso!',
          });
        })
        .catch(() => {
          setUsers((us) =>
            us.map((u) => {
              return u.id === user_id
                ? {
                    ...u,
                    loadingToogle: false,
                  }
                : u;
            }),
          );

          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Não foi possível alterar o status do usuário.',
            isClosable: true,
          });
        });
    },
    [addToast],
  );

  const handleUnlink = useCallback((user_id: number) => {
    setUnlinkUser(user_id);
  }, []);

  const handleUnlinkSubmit = useCallback(() => {
    if (!unlinkUser) return;

    const findUser = users.find((userFind) => userFind.id === unlinkUser);
    if (!findUser) {
      addToast({
        position: 'top-right',
        title: 'Ooops!!!',
        description: 'Não foi possível concluir a ação, tente novamente.',
        status: 'error',
        isClosable: true,
      });
      setUnlinkUser(null);
      return;
    }

    setLoadingRemove(true);

    api
      .put(`responsibilities/user/${findUser.responsibility_id}`, {
        user_id: findUser.id,
      })
      .then(() => {
        setLoadReload((state) => state + 1);
        setUnlinkUser(null);

        addToast({
          position: 'top-right',
          title: 'Acesso do usuário foi removido',
          description:
            'O cargo e as permissões de acesso ao sistema foram removidas',
          status: 'success',
          isClosable: true,
        });
      })
      .catch((err) => {
        if (err.response.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
            isClosable: true,
          });

          return;
        }

        addToast({
          position: 'top-right',
          title: 'Ooops!!!',
          description: 'Não foi possível concluir a ação, tente novamente.',
          status: 'error',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingRemove(false);
      });
  }, [addToast, unlinkUser, users]);

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido!'),
          responsibility: Yup.string().required('Selecione um cargo'),
          department: Yup.string().required('Selecione um cargo'),
          password: Yup.string()
            .required('Senha é obrigatória')
            .min(6, 'Mínimo 6 caracteres')
            .max(30, 'Máximo 30 caracteres'),
          password_confirmation: Yup.string()
            .when('password', {
              is: (val) => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.send_email = toggleSendEmail;

        if (idSignature) data.subscription_id = idSignature;

        const response = await api.post<UserData>('users', data);

        const newUser = response.data;
        setSubscription((state) => ({
          ...state,
          subscription_available_licenses_count: subscription
            ? subscription.subscription_available_licenses_count - 1
            : 0,
        }));
        setUsers([
          {
            ...newUser,
            responsibility: newUser.responsibility?.name,
            is_active: true,
            subscription_id: Number(idSignature),
          },
          ...users,
        ]);

        addToast({
          position: 'top-right',
          status: 'success',
          title: 'Usuário cadastrado!',
          description:
            'As informações do usuário foram cadastradas com sucesso.',
          isClosable: true,
        });

        formRef.current?.reset();

        // setLoadReload((state) => state + 1);

        onClose();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Erro ao cadastrar o usuário',
          description: err.response.data?.error,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, onClose, users, idSignature, toggleSendEmail, subscription],
  );

  const onCloseUnlink = useCallback(() => {
    setUnlinkUser(null);
  }, []);

  /*  const handleSearch = useCallback(
    async (searchValue: string) => {
      setSearchActive(true);
      setLoadingSearch(true);
      try {
        const response = await api.get<UserInterface[]>('company/users/all', {
          params: {
            search: searchValue,
          },
        });

        setUsers(response.data);
      } catch (err) {
        if (err.response.status < 500) {
          addToast({
            position: 'top-right',
            status: 'error',
            title: 'Ooops!!!',
            description:
              err.response.data?.error || 'Ocorreu um erro, tente novamente.',
            isClosable: true,
          });

          return;
        }

        addToast({
          position: 'top-right',
          status: 'error',
          title: 'Ooops!!!',
          description: 'Ocorreu um erro, tente novamente.',
          isClosable: true,
        });
      } finally {
        setLoadingSearch(false);
      }
    },
    [addToast],
  ); */

  const handlePageUserEdit = (userId: number): void => {
    history.push({
      pathname: `/controle/usuarios/${userId}`,
      search:
        usersFiltered?.is_active !== '' ||
        usersFiltered?.responsibility_id !== ''
          ? `resp=${usersFiltered?.responsibility_id}&status=${usersFiltered?.is_active}`
          : undefined,
    });
  };

  const handlePageJourneyWork = (userId: number): void => {
    history.push({
      pathname: `usuarios/jornada/${userId}`,
      search:
        usersFiltered?.is_active !== '' ||
        usersFiltered?.responsibility_id !== ''
          ? `resp=${usersFiltered?.responsibility_id}&status=${usersFiltered?.is_active}`
          : undefined,
    });
  };

  return (
    <Box id="container">
      <Box pos="relative">
        {loading && <LoadingAbsolute z_index={1111} min_height={500} />}

        <SectionHeader
          title="Controle de usuários"
          pagename="Usuários"
          goBackPage
        >
          <Flex>
            <Button
              aria-label="help"
              onClick={() => {
                setEnableTourUser(true);
              }}
              variant="outline"
              colorScheme="blue"
              mr={3}
            >
              <FiHelpCircle />
              <Text ml={2}>Ajuda</Text>
            </Button>
            {!idSignature && !user.is_subscribed && (
              <Button
                id="createUser"
                leftIcon={<FiPlus size={20} />}
                colorScheme="blue"
                variant="solid"
                onClick={onOpen}
                ref={btnRef}
                isLoading={loadingDataForm}
              >
                Cadastrar
              </Button>
            )}
          </Flex>
        </SectionHeader>

        {idSignature && (
          <Box>
            {idSignature && (
              <Flex>
                <Text fontSize="xl">Assinatura:</Text>
                <Text fontSize="xl" as="b" ml={3}>
                  #{idSignature}
                </Text>
              </Flex>
            )}
            <Flex>
              <Text fontSize="xl">Licenças disponiveis:</Text>
              <Text fontSize="xl" as="b" ml={3}>
                {subscription?.subscription_available_licenses_count}
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="xl">Licenças totais:</Text>
              <Text fontSize="xl" as="b" ml={3}>
                {subscription?.licenses_qty}
              </Text>
            </Flex>
          </Box>
        )}

        <Flex justifyContent="flex-end" alignItems="flex-start" mb={5}>
          <Search
            loading={loadingSearch}
            results={countData}
            searchValue={(value) => setSearch(value)}
            searchClear={() => setSearch('')}
            tooltipPlacement="top-start"
          />
          <IconButton
            aria-label="Filtros"
            icon={<FiFilter />}
            size="lg"
            borderRadius="md"
            ml={3}
            onClick={onOpenFilter}
            ref={btnFilterRef}
          />
        </Flex>

        {usersFiltered &&
          (usersFiltered?.is_active !== '' ||
            usersFiltered?.responsibility_id !== '') && (
            <FilterTags
              data={[
                {
                  value:
                    status.find((st) => st.value === usersFiltered?.is_active)
                      ?.label || '',
                  icon: FiFlag,
                  label: 'Status',
                },
                {
                  value:
                    responsibilities.find(
                      (resp) =>
                        String(resp.value) === usersFiltered?.responsibility_id,
                    )?.label || '',
                  icon: FiShield,
                  label: 'Cargo',
                },
              ]}
              cleanFilter={() => {
                setUsersFiltered(undefined);
              }}
              variant="outline"
            />
          )}

        {!loading && (!users || !users?.length) && (
          <Box>
            <Alert status="info" variant="left-accent" mb={4}>
              <AlertIcon />
              Nenhum usuário foi encontrado, limpe o filtro e tente novamente
            </Alert>
          </Box>
        )}

        <Grid
          templateColumns={['1fr', 'repeat(auto-fill, minmax(250px, 1fr))']}
          // justifyContent="space-around"
          gap={[2, 6]}
        >
          {!loading && (!users || !users?.length) ? (
            <Box>
              {idSignature &&
                subscription?.subscription_available_licenses_count !== 0 && (
                  <CardLicense
                    id="createUser"
                    borderStyle="dashed"
                    borderWidth="3px"
                    borderColor="gray.300"
                    w="100%"
                    h="150px"
                    rounded="md"
                    alignItems="center"
                    justifyContent="space-around"
                    onClick={onOpen}
                  >
                    <IconButton
                      aria-label="Edit"
                      icon={<FiPlus size={25} />}
                      rounded="100%"
                      background="gray.300"
                      width="65px"
                      height="65px"
                    />
                    <Text color="gray.500">Adicionar usuário a Licença</Text>
                  </CardLicense>
                )}
            </Box>
          ) : (
            <>
              {idSignature &&
                subscription?.subscription_available_licenses_count !== 0 && (
                  <CardLicense
                    id="createUser"
                    borderStyle="dashed"
                    borderWidth="3px"
                    borderColor="gray.300"
                    w="100%"
                    h="150px"
                    rounded="md"
                    alignItems="center"
                    justifyContent="space-around"
                    onClick={onOpen}
                  >
                    <IconButton
                      aria-label="Edit"
                      icon={<FiPlus size={25} />}
                      rounded="100%"
                      background="gray.300"
                      width="65px"
                      height="65px"
                    />
                    <Text color="gray.500">Adicionar usuário a Licença</Text>
                  </CardLicense>
                )}
              {users &&
                users?.length > 0 &&
                users.map((currentUser, key) => (
                  <Box
                    bg="white"
                    borderWidth="1px"
                    w="100%"
                    p="30px 0 0"
                    rounded="md"
                    position="relative"
                    display="flex"
                    flexDir="column"
                    justifyContent="space-between"
                    textAlign="center"
                    borderColor="gray.100"
                    key={`${currentUser.id}-us-${currentUser.responsibility_id}`}
                  >
                    <Flex>
                      <Badge
                        variant="subtle"
                        colorScheme={!currentUser.is_active ? 'red' : 'green'}
                        pos="absolute"
                        top="5px"
                        right="5px"
                        shadow="0 0 5px rgba(255,255,255,1)"
                        fontSize="0.7rem"
                      >
                        {!currentUser.is_active ? 'Bloqueado' : 'Ativo'}
                      </Badge>
                      <Box id={key === 0 ? 'editUser1' : ''}>
                        <Tooltip
                          placement="top"
                          hasArrow
                          aria-label="Editar"
                          label="Editar"
                        >
                          <HoverAvatar>
                            <AvatarCustom
                              src={currentUser.avatar}
                              name={currentUser.name}
                              size="lg"
                            />

                            <IconButton
                              aria-label="Edit"
                              icon={<FiEdit />}
                              onClick={() => handlePageUserEdit(currentUser.id)}
                            />
                          </HoverAvatar>
                        </Tooltip>
                      </Box>
                      <Flex
                        justifyContent="space-between"
                        flex={1}
                        px={2}
                        ml={4}
                      >
                        <Flex flexDir="column" alignItems="flex-start">
                          <Heading
                            isTruncated
                            as="h4"
                            size="sm"
                            w={150}
                            textAlign="left"
                          >
                            {currentUser.name}
                          </Heading>

                          <Tooltip
                            hasArrow
                            aria-label={currentUser.email}
                            label={currentUser.email}
                            zIndex={1}
                          >
                            <Text
                              isTruncated
                              textAlign="initial"
                              color="gray.500"
                              fontSize="xs"
                              w={150}
                              pr={2}
                            >
                              {currentUser.email}
                            </Text>
                          </Tooltip>

                          <Heading
                            d="inline-flex"
                            alignItems="center"
                            mt={1}
                            justifyContent="center"
                            fontSize="xs"
                            color="gray.500"
                          >
                            {currentUser?.responsibility ? (
                              <>
                                <FiShield size={14} />
                                <Text ml={1}>{currentUser.responsibility}</Text>
                              </>
                            ) : (
                              <Text color="red.500">Sem cargo</Text>
                            )}
                          </Heading>
                        </Flex>
                        {user.is_subscribed && !idSignature && (
                          <Text fontSize="2xl">
                            {`#${currentUser.subscription?.id}`}
                          </Text>
                        )}
                      </Flex>
                    </Flex>

                    <Flex
                      id={key === 0 ? 'BlockUser1' : ''}
                      mt={4}
                      borderRadius="0 0 5px 5px"
                      flex={1}
                      borderTopColor="gray.200"
                      borderTopWidth={1}
                      backgroundColor="gray.100"
                    >
                      {/* <Tooltip
                    hasArrow
                    aria-label={
                      currentUser.responsibility_id
                        ? 'Remover cargo e suas permissões'
                        : 'O usuário não possui cargo'
                    }
                    label={
                      currentUser.responsibility_id
                        ? 'Remover cargo e suas permissões'
                        : 'O usuário não possui cargo'
                    }
                  >
                    <Box width="full">
                      <Button
                        width="100%"
                        variant="ghost"
                        colorScheme="red"
                        color="gray.500"
                        fontWeight="500"
                        leftIcon={<FiXOctagon />}
                        onClick={() => handleUnlink(currentUser.id)}
                        disabled={!currentUser.responsibility_id}
                      >
                        Remover
                      </Button>
                    </Box>
                  </Tooltip> */}

                      <Button
                        width="100%"
                        variant="ghost"
                        colorScheme={!currentUser.is_active ? 'green' : 'red'}
                        color="gray.500"
                        leftIcon={
                          !currentUser.is_active ? <FiPower /> : <FiSlash />
                        }
                        fontWeight="500"
                        onClick={() => {
                          if (currentUser.department_id) {
                            handleToggleStatusUser(
                              currentUser.id,
                              currentUser.department_id,
                              !!currentUser?.is_active,
                            );
                          } else {
                            addToast({
                              position: 'top-right',
                              status: 'warning',
                              title: 'Funcionário sem departamento',
                              description:
                                'Antes de ativar o usuário, selecione um departamento e salve a alteração',
                              isClosable: true,
                            });
                          }
                        }}
                        isLoading={currentUser.loadingToogle}
                      >
                        {!currentUser.is_active ? 'Ativar' : 'Bloquear'}
                      </Button>
                      {!user.is_subscribed && (
                        <Tooltip
                          label="Jornada de Trabalho"
                          placement="bottom-start"
                        >
                          <IconButton
                            aria-label="jornada de trabalho"
                            icon={<FaBriefcase />}
                            onClick={() =>
                              handlePageJourneyWork(currentUser.id)
                            }
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  </Box>
                ))}
            </>
          )}
        </Grid>

        {currentPage < lastPage && (
          <Stack p={2} justifyContent="center">
            <Button
              colorScheme="teal"
              variant="ghost"
              rightIcon={<FiChevronDown />}
              onClick={handleNextPage}
            >
              Carregar mais
            </Button>
          </Stack>
        )}

        {/* <div>
          <h3>Usuários sem cargo</h3>
          <p>
            Listar todos os usuários vinculado a empresa, criar tabela para
            relacionar usuário/empresa
          </p>
        </div> */}
      </Box>

      <Drawer
        preserveScrollBarGap
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Cadastrar usuário</DrawerHeader>

          <DrawerBody>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="name"
                type="text"
                placeholder="Nome"
                icon={FiUser}
                autoFocus
              />
              <Input
                name="email"
                type="email"
                placeholder="E-mail"
                icon={FiMail}
              />
              <Input
                isPassword
                name="password"
                type="password"
                placeholder="Senha"
                defaultValue={user.company_id === 1 ? '@ipaconline123' : ''}
                icon={FiLock}
                containerStyle={{ marginTop: 24 }}
                minLength={6}
                maxLength={30}
              />
              <Input
                isPassword
                name="password_confirmation"
                type="password"
                placeholder="Confirmar senha"
                defaultValue={user.company_id === 1 ? '@ipaconline123' : ''}
                icon={FiLock}
                minLength={6}
                maxLength={30}
              />

              <Select
                name="responsibility"
                options={responsibilities.filter((resp) => resp.value !== '0')}
                placeholder="Selecione o cargo"
                containerStyle={{ marginTop: 20 }}
              />

              <Select
                name="department"
                options={departments}
                placeholder="Selecione o departamento"
                containerStyle={{ marginTop: 20 }}
              />
              <Flex>
                <FormLabel htmlFor="toggleSendEmail">
                  Enviar email para usuario cadastrado?
                </FormLabel>

                <Switch
                  id="toggleSendEmail"
                  onChange={() => {
                    setToggleSendEmail(!toggleSendEmail);
                  }}
                  defaultIsChecked={toggleSendEmail}
                  size="sm"
                />
              </Flex>
            </Form>
          </DrawerBody>

          <DrawerFooter>
            {!loading && (
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
            )}
            <Button
              variant="solid"
              colorScheme="blue"
              onClick={() => formRef.current?.submitForm()}
              isLoading={loading}
              isDisabled={loading}
            >
              Confirmar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        title="Remover Acesso?"
        description="O cargo e todas as permissões de acesso ao sistema serão removidas
        do usuário."
        isOpen={!!unlinkUser}
        leastDestructiveRef={cancelRef}
        onClose={onCloseUnlink}
        onSubmit={handleUnlinkSubmit}
        isLoading={loadingRemove}
      />

      <Filter
        title="Filtrar Usuários"
        isOpen={isOpenFilter}
        onClose={onCloseFilter}
        finalFocusRef={btnFilterRef}
        submit={(obData) => handleFilter(obData)}
        initialData={usersFiltered}
        loading={loading}
      >
        <RadioChakra name="is_active" label="Status" options={status} />
        <Select
          label="Cargos"
          placeholder="Todos"
          name="responsibility_id"
          options={responsibilities}
          containerStyle={{ marginTop: 20 }}
        />
      </Filter>
      {!loading && (
        <StepsIntro
          enabled={enableTourUser}
          onExit={() => setEnableTourUser(false)}
          steps={stepsUser}
        />
      )}
    </Box>
  );
};

export default User;
