import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { FaArrowsAltH, FaTasks } from 'react-icons/fa';
import { Link, useLocation, useParams } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Avatar,
  Progress,
  Divider,
  Badge,
  Tooltip,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as XLSX from 'xlsx-js-style';
import * as FileSaver from 'file-saver';
import queryString from 'query-string';

import BadgeStatusInfo from '~/modules/dashboard/components/BadgeStatusInfo';
import { AllocationProp } from '~/modules/dashboard/components/BarAllocation';
import ToggleCustom from '~/modules/dashboard/components/ToggleCustom';
import InputChakra from '~/shared/components/InputChakra';
import LoadingAbsolute from '~/shared/components/LoadingAbsolute';
import ModalChakra from '~/shared/components/Modal/ChakraUI';
import SectionHeader from '~/shared/components/SectionHeader';
import { THeadProps } from '~/shared/components/Table';
import api from '~/shared/services/api';
import exportExcel from '~/utils/exportExcel';

import { ProgressCustom, PercentEmployee } from './styles';

import { QueryParamsAllocation } from '..';

interface Department {
  id: string;
  created_at: string;
  updated_at: string;
  alocacao: number;
  alocacao_prevista: number;
  alocacao_geral: number;
  alocacao_prevista_geral: number;
  name: string;
  responsible: string;
  company_id: number;
  responsible_id: number;
  superior_department_id: number;
  funcionarios: Employee[];
}

interface Employee {
  id: number;
  nome: string;
  status_id: number;
  status_nome: string;
  nome_original: string;
  total_alocacao_prevista: number;
  total_alocacao: number;
  total_turnos: number;
  total_alocacao_prevista_percent: number;
  total_alocacao_percent: number;
  alocacao_data: AllocationProp[];
  departamento_id: string;
}

interface Team {
  id: number;
  departamento_superior_id: number;
  empresa_id: number;
  responsavel_id: string;
  name: string;
  created_at: string;
  updated_at: string;
  nome_responsavel: string;
  alocacao: number;
  alocacao_prevista: number;
  funcionarios: Employee[];
}

interface AllocationDepartment {
  departamento: Department;
  equipes: Team[];
}

interface ParamsUrl {
  id?: string;
}

interface Activities {
  referente: string;
  category: string;
  attendance_id: number;
  activity: string;
  expected_duration: string;
  activity_start: string;
  activity_end: string;
  real_duration: string;
  date_time_start_planning: string;
  date_time_end_planning: string;
  fatal_date_time: string;
}

interface Attendance {
  attendance_id: number;
  category: string;
  referente: string;
  demand: string;
  fantasy: string;
  service_description: string;
  request: string;
  month_request: string;
  competence: string;
  employee: string;
  activity: string;
  date_time_start_planning: string;
  date_time_end_planning: string;
  fatal_date_time: string;
  expected_duration: string;
  real_duration: string;
  activities: Activities[];
}

interface FullPerformance {
  category: string;
  demand_amount: number;
  fantasy_name_amount: number;
  employee: string;
  expected_duration: string;
  real_duration: string;
}

interface AllocationEmployee {
  data: Attendance[];
  full_performance: FullPerformance;
}

const AllocationDepartment: React.FC = () => {
  const addToast = useToast();
  const formRef = useRef<FormHandles>(null);
  const [department, setDepartment] = useState<Department>();
  const [selectedUser, setSelectedUser] = useState<Employee>();
  const [teams, setTeams] = useState<Team[]>([]);
  const location = useLocation();
  const { id: departmentId } = useParams<ParamsUrl>();

  const [loading, setLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  // const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [attendance, setAttendance] = useState<Attendance[]>([]);

  const startDate = format(new Date(), 'yyyy-MM');

  const queryParams = useMemo<QueryParamsAllocation>(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  const [
    toggleAdjustExpectedAllocation,
    setToggleAdjustExpectedAllocation,
  ] = useState(() => !queryParams?.adjust || queryParams?.adjust === '1');

  const goLink = useMemo(() => {
    // Lembrando que o ajuste da alocação prevista pode alterar
    return `/relatorios/alocacao?start_date=${
      queryParams.start_date
    }&end_date=${queryParams.end_date}&adjust=${
      toggleAdjustExpectedAllocation ? 1 : 0
    }`;
  }, [queryParams, toggleAdjustExpectedAllocation]);

  const tableTitlesExcel = useMemo((): THeadProps[] => {
    return [
      {
        title: 'Categoria',
      },
      {
        title: 'Demanda',
      },
      {
        title: 'Empresa',
      },
      {
        title: 'Cód Atendimento',
      },
      {
        title: 'Descrição Atendimento',
      },
      {
        title: 'Solicitação',
      },
      {
        title: 'Mês de Solicitação',
      },
      {
        title: 'Competência',
      },
      {
        title: 'Atividade',
      },
      {
        title: 'Duração Prevista',
      },
      {
        title: 'Duração Realizada',
      },
      {
        title: 'Inicio da Atividade',
      },
      {
        title: 'Fim da Atividade',
      },
      {
        title: 'Data e Hora Inicio Planejamento',
      },
      {
        title: 'Data e Hora Fim Planejamento',
      },
      {
        title: 'Data e Hora Fatal',
      },
    ];
  }, []);

  /* if (queryParams?.start_date && queryParams?.end_date) {
    format(parseISO(queryParams.start_date), 'YYYY-MM-DD');
    format(parseISO(queryParams.end_date), 'YYYY-MM-DD');
  } */

  useEffect(() => {
    setLoading(true);
    const { CancelToken } = axios;
    const source = CancelToken.source();

    api
      .get<AllocationDepartment>(`/allocation/department/${departmentId}`, {
        cancelToken: source.token,
        params: {
          start_date: queryParams?.start_date,
          end_date: queryParams?.end_date,
          adjust_expected_allocation: toggleAdjustExpectedAllocation ? 1 : 0,
        },
      })
      .then((response) => {
        // ${userId}
        const { departamento, equipes } = response.data;

        // console.log(toggleAdjustExpectedAllocation, queryParams.adjust);
        // console.log(equipes);
        setDepartment({
          ...departamento,
          alocacao: Math.round(departamento?.alocacao),
          alocacao_geral: Math.round(departamento?.alocacao_geral),
          alocacao_prevista: Math.round(departamento?.alocacao_prevista),
          alocacao_prevista_geral: Math.round(
            departamento?.alocacao_prevista_geral,
          ),
        });
        if (equipes?.length) {
          setTeams(
            equipes?.map((team) => {
              return {
                ...team,
                alocacao: Math.round(team.alocacao),
                alocacao_prevista: Math.round(team.alocacao_prevista),
                funcionarios: team.funcionarios.map((func) => {
                  return {
                    ...func,
                    total_alocacao: Math.round(func.total_alocacao),
                    total_alocacao_percent: Math.round(
                      func.total_alocacao_percent,
                    ),
                    total_alocacao_prevista: Math.round(
                      func.total_alocacao_prevista,
                    ),
                    total_alocacao_prevista_percent: Math.round(
                      func.total_alocacao_prevista_percent,
                    ),
                    total_turnos: Math.round(func.total_turnos),
                    alocacao_data: func.alocacao_data?.map((alloc) => {
                      return {
                        ...alloc,
                        alocacao_percent: Math.round(alloc.alocacao_percent),
                        alocacao_prevista_percent: Math.round(
                          alloc.alocacao_prevista_percent,
                        ),
                        total_alocacao: Math.round(alloc.total_alocacao),
                        total_alocacao_prevista: Math.round(
                          alloc.total_alocacao_prevista,
                        ),
                        total_turnos: Math.round(alloc.total_turnos),
                        data_alocacao: format(
                          parseISO(alloc.data_alocacao),
                          "d' 'LLL",
                          { locale: ptBR },
                        ),
                        format_date_allocation: format(
                          parseISO(alloc.data_alocacao),
                          'yyyy-MM-dd',
                        ),
                      };
                    }),
                  };
                }),
              };
            }),
          );
        }
      })
      .finally(() => setLoading(false));

    return () => {
      source.cancel('Operação cancelada pelo usuário');
    };
  }, [queryParams, departmentId, toggleAdjustExpectedAllocation]);

  const indexDefaultDepartment = useMemo(() => {
    // console.log(teams.findIndex((tm) => tm.id === Number(departmentId)));
    return teams.findIndex((tm) => tm.id === Number(departmentId));
  }, [departmentId, teams]);

  const handleExportTable = useCallback(
    (
      performanceAllocation: AllocationEmployee,
      startDateForm: string,
      endDateForm: string,
    ): void => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      // Fazer um for utilizando o range para o header
      // console.log('datas', startDateForm, endDateForm);

      const fullPerformance = performanceAllocation.full_performance;
      const ws: any = {};

      const startDateFormat = format(parseISO(startDateForm), 'MM-yyyy');
      const endDateFormat = endDateForm
        ? format(parseISO(endDateForm), 'MM-yyyy')
        : '';

      const sheetTitle = `Desempenho de ${
        fullPerformance.employee
      } a partir de ${startDateFormat} até ${endDateFormat || startDateFormat}`;

      const headerHeight = 2; // Linhas que o titulo da tabela ocupa, os dados iniciarão na linha seguinte

      ws.A1 = {
        v: sheetTitle,
        s: {
          font: { bold: true, sz: 16 },
        },
      };

      const sumScore = 0;

      const countActiv = performanceAllocation.data?.reduce(
        (accumulator, perf) => {
          // console.log(accumulator);
          // console.log(conc);
          return accumulator + perf.activities.length;
        },
        sumScore,
      );

      const rangeData = {
        s: { c: 0, r: 0 },
        e: {
          c: 16,
          r: performanceAllocation.data.length + countActiv + headerHeight + 1, // +1 adicionado por conta da linha Funcionario
        },
      };
      const rangeHeader = {
        s: { c: 0, r: headerHeight },
        e: { c: 16, r: headerHeight },
      };

      const borderStyle = {
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
        },
      };

      const cellHeaderStyles = {
        font: {
          sz: 12,
          color: { rgb: '#FF000000' },
          bold: true,
        },
      };

      const cellStylesAtt = {
        font: {
          sz: 10,
          color: { rgb: '#FF000000' },
          bold: false,
        },
        fill: { fgColor: { rgb: 'fcd5b4' } },
      };

      const cellStyles = {
        font: {
          sz: 10,
          color: { rgb: '#FF000000' },
          bold: false,
        },
      };

      const wscols: { wch: number }[] = [];
      // row
      for (let row = rangeHeader.s.r; row <= rangeHeader.e.r; row += 1) {
        for (let col = rangeHeader.s.c; col <= rangeHeader.e.c; col += 1) {
          const cellRef = XLSX.utils.encode_cell({ c: col, r: row });

          if (col === 0) {
            ws[cellRef] = { v: 'Referente', s: cellHeaderStyles };
            wscols.push({ wch: 20 });
          } else if (col === 1) {
            ws[cellRef] = { v: 'Categoria', s: cellHeaderStyles };
            wscols.push({ wch: 20 });
          } else if (col === 2) {
            ws[cellRef] = {
              v: `Demanda [${fullPerformance.demand_amount}]`,
              s: cellHeaderStyles,
            };
            wscols.push({ wch: 30 });
          } else if (col === 3) {
            ws[cellRef] = {
              v: `Empresas [${fullPerformance.fantasy_name_amount}]`,
              s: cellHeaderStyles,
            };
            wscols.push({ wch: 30 });
          } else if (col === 4) {
            ws[cellRef] = { v: 'Cód Atendimento', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 5) {
            ws[cellRef] = { v: 'Descrição Atendimento', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 6) {
            ws[cellRef] = { v: 'Solicitação', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 7) {
            ws[cellRef] = { v: 'Mês de Solicitação', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 8) {
            ws[cellRef] = { v: 'Competência', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 9) {
            ws[cellRef] = { v: 'Empresas', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 10) {
            ws[cellRef] = { v: 'Duração Prevista', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 11) {
            ws[cellRef] = { v: 'Duração Realizada', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 12) {
            ws[cellRef] = { v: 'Inicio da Atividade', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 13) {
            ws[cellRef] = { v: 'Fim da Atividade', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          } else if (col === 14) {
            ws[cellRef] = {
              v: 'Data e Hora Inicio Planejamento',
              s: cellHeaderStyles,
            };
            wscols.push({ wch: 30 });
          } else if (col === 15) {
            ws[cellRef] = {
              v: 'Data e Hora Fim Planejamento',
              s: cellHeaderStyles,
            };
            wscols.push({ wch: 30 });
          } else if (col === 16) {
            ws[cellRef] = { v: 'Data e Hora Fatal', s: cellHeaderStyles };
            wscols.push({ wch: 30 });
          }
          /* else {
            ws[cellRef] = {
              v: monthNames[col - 2].title,
              s: { ...cellStyles, alignment: { horizontal: 'center' } },
            };
            wscols.push({ wch: 15 });
          } */
        }
      }
      ws['!cols'] = wscols;

      const rowRefFunc = headerHeight + 1;

      const cellRefReferenteFunc = XLSX.utils.encode_cell({
        c: 0,
        r: rowRefFunc,
      });
      const cellRefCategoryFunc = XLSX.utils.encode_cell({
        c: 1,
        r: rowRefFunc,
      });
      const cellRefDemandFunc = XLSX.utils.encode_cell({
        c: 2,
        r: rowRefFunc,
      });
      const cellRefCompanyFunc = XLSX.utils.encode_cell({
        c: 3,
        r: rowRefFunc,
      });
      const cellRefCodAttenFunc = XLSX.utils.encode_cell({
        c: 4,
        r: rowRefFunc,
      });
      const cellRefDescAttenFunc = XLSX.utils.encode_cell({
        c: 5,
        r: rowRefFunc,
      });
      const cellRefSolicitationFunc = XLSX.utils.encode_cell({
        c: 6,
        r: rowRefFunc,
      });
      const cellRefMonthSolicitationFunc = XLSX.utils.encode_cell({
        c: 7,
        r: rowRefFunc,
      });
      const cellRefCompetenceFunc = XLSX.utils.encode_cell({
        c: 8,
        r: rowRefFunc,
      });
      const cellRefActivityFunc = XLSX.utils.encode_cell({
        c: 9,
        r: rowRefFunc,
      });
      const cellRefExpectedDurationFunc = XLSX.utils.encode_cell({
        c: 10,
        r: rowRefFunc,
      });
      const cellRefRealDurationFunc = XLSX.utils.encode_cell({
        c: 11,
        r: rowRefFunc,
      });
      const cellRefStartActivityFunc = XLSX.utils.encode_cell({
        c: 12,
        r: rowRefFunc,
      });
      const cellRefEndActivityFunc = XLSX.utils.encode_cell({
        c: 13,
        r: rowRefFunc,
      });
      const cellRefStartDateTimeFunc = XLSX.utils.encode_cell({
        c: 14,
        r: rowRefFunc,
      });
      const cellRefEndDateTimeFunc = XLSX.utils.encode_cell({
        c: 15,
        r: rowRefFunc,
      });
      const cellRefDateTimefatalFunc = XLSX.utils.encode_cell({
        c: 16,
        r: rowRefFunc,
      });

      ws[cellRefReferenteFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefCategoryFunc] = {
        v: fullPerformance.category,
        s: cellStyles,
      };
      ws[cellRefDemandFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefCompanyFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefCodAttenFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefDescAttenFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefSolicitationFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefMonthSolicitationFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefCompetenceFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefActivityFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefExpectedDurationFunc] = {
        v: fullPerformance.expected_duration,
        s: cellStyles,
      };
      ws[cellRefRealDurationFunc] = {
        v: fullPerformance.real_duration || '--',
        s: cellStyles,
      };
      ws[cellRefStartActivityFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefEndActivityFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefStartDateTimeFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefEndDateTimeFunc] = {
        v: '--',
        s: cellStyles,
      };
      ws[cellRefDateTimefatalFunc] = {
        v: '--',
        s: cellStyles,
      };

      let rowRef = headerHeight + 2;

      // row
      performanceAllocation.data.forEach((perf) => {
        // console.log(iPerf, perf);

        // rowRef += iPerf;

        const cellRefReferente = XLSX.utils.encode_cell({ c: 0, r: rowRef });
        const cellRefCategory = XLSX.utils.encode_cell({ c: 1, r: rowRef });
        const cellRefDemand = XLSX.utils.encode_cell({ c: 2, r: rowRef });
        const cellRefCompany = XLSX.utils.encode_cell({ c: 3, r: rowRef });
        const cellRefCodAtten = XLSX.utils.encode_cell({ c: 4, r: rowRef });
        const cellRefDescAtten = XLSX.utils.encode_cell({ c: 5, r: rowRef });
        const cellRefSolicitation = XLSX.utils.encode_cell({
          c: 6,
          r: rowRef,
        });
        const cellRefMonthSolicitation = XLSX.utils.encode_cell({
          c: 7,
          r: rowRef,
        });
        const cellRefCompetence = XLSX.utils.encode_cell({ c: 8, r: rowRef });
        const cellRefActivity = XLSX.utils.encode_cell({ c: 9, r: rowRef });
        const cellRefExpectedDuration = XLSX.utils.encode_cell({
          c: 10,
          r: rowRef,
        });
        const cellRefRealDuration = XLSX.utils.encode_cell({
          c: 11,
          r: rowRef,
        });
        const cellRefStartActivity = XLSX.utils.encode_cell({
          c: 12,
          r: rowRef,
        });
        const cellRefEndActivity = XLSX.utils.encode_cell({
          c: 13,
          r: rowRef,
        });
        const cellRefStartDateTime = XLSX.utils.encode_cell({
          c: 14,
          r: rowRef,
        });
        const cellRefEndDateTime = XLSX.utils.encode_cell({
          c: 15,
          r: rowRef,
        });
        const cellRefDateTimefatal = XLSX.utils.encode_cell({
          c: 16,
          r: rowRef,
        });

        ws[cellRefReferente] = {
          v: perf.referente || '--',
          s: cellStylesAtt,
        };
        ws[cellRefCategory] = {
          v: perf.category || '',
          s: cellStylesAtt,
        };
        ws[cellRefDemand] = {
          v: perf.demand,
          s: cellStylesAtt,
        };
        ws[cellRefCompany] = {
          v: perf.fantasy,
          s: cellStylesAtt,
        };
        ws[cellRefCodAtten] = {
          v: perf.attendance_id,
          s: cellStylesAtt,
        };
        ws[cellRefDescAtten] = {
          v: perf.service_description || '--',
          s: cellStylesAtt,
        };
        ws[cellRefSolicitation] = {
          v: perf.request
            ? format(parseISO(perf.request), "dd/MM/yyyy '-' hh:mm:ss")
            : '--',
          s: cellStylesAtt,
        };
        ws[cellRefMonthSolicitation] = {
          v: perf.month_request,
          s: cellStylesAtt,
        };
        ws[cellRefCompetence] = {
          v: perf.competence || '--',
          s: cellStylesAtt,
        };
        ws[cellRefActivity] = {
          v: perf.activity || '--',
          s: cellStylesAtt,
        };
        ws[cellRefExpectedDuration] = {
          v: perf.expected_duration,
          s: cellStylesAtt,
        };
        ws[cellRefRealDuration] = {
          v: perf.real_duration || '--',
          s: cellStylesAtt,
        };
        ws[cellRefStartActivity] = {
          v: perf.date_time_start_planning
            ? format(
                parseISO(perf.date_time_start_planning),
                "dd/MM/yyyy '-' hh:mm:ss",
              )
            : '--',
          s: cellStylesAtt,
        };
        ws[cellRefEndActivity] = {
          v: perf.date_time_end_planning
            ? format(
                parseISO(perf.date_time_end_planning),
                "dd/MM/yyyy '-' hh:mm:ss",
              )
            : '--',
          s: cellStylesAtt,
        };
        ws[cellRefStartDateTime] = {
          v: perf.date_time_start_planning
            ? format(
                parseISO(perf.date_time_start_planning),
                "dd/MM/yyyy '-' hh:mm:ss",
              )
            : '--',
          s: cellStylesAtt,
        };
        ws[cellRefEndDateTime] = {
          v: perf.date_time_end_planning
            ? format(
                parseISO(perf.date_time_end_planning),
                "dd/MM/yyyy '-' hh:mm:ss",
              )
            : '--',
          s: cellStylesAtt,
        };
        ws[cellRefDateTimefatal] = {
          v: perf.fatal_date_time
            ? format(parseISO(perf.fatal_date_time), "dd/MM/yyyy '-' hh:mm:ss")
            : '--',
          s: cellStylesAtt,
        };

        let rowRefAct = 0;

        perf.activities.forEach((act, iAct) => {
          // const dataExcel: FormattedDataCompany = {} as FormattedDataCompany;
          rowRefAct = iAct + (rowRef + 1);

          const cellRefReferentAct = XLSX.utils.encode_cell({
            c: 0,
            r: rowRefAct,
          });
          const cellRefCategoryAct = XLSX.utils.encode_cell({
            c: 1,
            r: rowRefAct,
          });
          const cellRefDemandAct = XLSX.utils.encode_cell({
            c: 2,
            r: rowRefAct,
          });
          const cellRefCompanyAct = XLSX.utils.encode_cell({
            c: 3,
            r: rowRefAct,
          });
          const cellRefCodAttenAct = XLSX.utils.encode_cell({
            c: 4,
            r: rowRefAct,
          });
          const cellRefDescAttenAct = XLSX.utils.encode_cell({
            c: 5,
            r: rowRefAct,
          });
          const cellRefSolicitationAct = XLSX.utils.encode_cell({
            c: 6,
            r: rowRefAct,
          });
          const cellRefMonthSolicitationAct = XLSX.utils.encode_cell({
            c: 7,
            r: rowRefAct,
          });
          const cellRefCompetenceAct = XLSX.utils.encode_cell({
            c: 8,
            r: rowRefAct,
          });
          const cellRefActivityAct = XLSX.utils.encode_cell({
            c: 9,
            r: rowRefAct,
          });
          const cellRefExpectedDurationAct = XLSX.utils.encode_cell({
            c: 10,
            r: rowRefAct,
          });
          const cellRefRealDurationAct = XLSX.utils.encode_cell({
            c: 11,
            r: rowRefAct,
          });
          const cellRefStartActivityAct = XLSX.utils.encode_cell({
            c: 12,
            r: rowRefAct,
          });
          const cellRefEndActivityAct = XLSX.utils.encode_cell({
            c: 13,
            r: rowRefAct,
          });
          const cellRefStartDateTimeAct = XLSX.utils.encode_cell({
            c: 14,
            r: rowRefAct,
          });
          const cellRefEndDateTimeAct = XLSX.utils.encode_cell({
            c: 15,
            r: rowRefAct,
          });
          const cellRefDateTimefatalAct = XLSX.utils.encode_cell({
            c: 16,
            r: rowRefAct,
          });

          ws[cellRefReferentAct] = {
            v: act.referente || '--',
            s: cellStyles,
          };
          ws[cellRefCategoryAct] = {
            v: act.category || '--',
            s: cellStyles,
          };
          ws[cellRefDemandAct] = {
            v: perf.demand,
            s: cellStyles,
          };
          ws[cellRefCompanyAct] = {
            v: perf.fantasy,
            s: cellStyles,
          };
          ws[cellRefCodAttenAct] = {
            v: act.attendance_id,
            s: cellStyles,
          };
          ws[cellRefDescAttenAct] = {
            v: perf.service_description || '--',
            s: cellStyles,
          };
          ws[cellRefSolicitationAct] = {
            v: perf.request
              ? format(parseISO(perf.request), "dd/MM/yyyy '-' hh:mm:ss")
              : '--',
            s: cellStyles,
          };
          ws[cellRefMonthSolicitationAct] = {
            v: perf.month_request,
            s: cellStyles,
          };
          ws[cellRefCompetenceAct] = {
            v: perf.competence || '--',
            s: cellStyles,
          };
          ws[cellRefActivityAct] = {
            v: act.activity || '--',
            s: cellStyles,
          };
          ws[cellRefExpectedDurationAct] = {
            v: act.expected_duration,
            s: cellStyles,
          };
          ws[cellRefRealDurationAct] = {
            v: act.real_duration || '--',
            s: cellStyles,
          };
          ws[cellRefStartActivityAct] = {
            v: act.activity_start
              ? format(parseISO(act.activity_start), "dd/MM/yyyy '-' hh:mm:ss")
              : '--',
            s: cellStyles,
          };
          ws[cellRefEndActivityAct] = {
            v: act.activity_end
              ? format(parseISO(act.activity_end), "dd/MM/yyyy '-' hh:mm:ss")
              : '--',
            s: cellStyles,
          };
          ws[cellRefStartDateTimeAct] = {
            v: act.date_time_start_planning
              ? format(
                  parseISO(act.date_time_start_planning),
                  "dd/MM/yyyy '-' hh:mm:ss",
                )
              : '--',
            s: cellStyles,
          };
          ws[cellRefEndDateTimeAct] = {
            v: act.date_time_end_planning
              ? format(
                  parseISO(act.date_time_end_planning),
                  "dd/MM/yyyy '-' hh:mm:ss",
                )
              : '--',
            s: cellStyles,
          };
          ws[cellRefDateTimefatalAct] = {
            v: act.fatal_date_time
              ? format(parseISO(act.fatal_date_time), "dd/MM/yyyy '-' hh:mm:ss")
              : '--',
            s: cellStyles,
          };
        });

        rowRef = rowRefAct + 1;
      });
      ws['!ref'] = XLSX.utils.encode_range(rangeData);
      ws['!autofilter'] = { ref: XLSX.utils.encode_range(rangeHeader) };
      const wb = {
        Sheets: { 'Relatório de Desempenho': ws },
        SheetNames: ['Relatório de Desempenho'],
      };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      const filename = `Relatório de Desempenho - ${
        fullPerformance.employee
      } - ${startDateFormat} - ${endDateFormat || startDateFormat}`;
      FileSaver.saveAs(data, filename + fileExtension);
    },
    [],
  );

  const handleExportExcel = useCallback(
    async (dataForm: any) => {
      // console.log(dataForm);
      setLoadingExcel(true);
      await api
        .get<AllocationEmployee>(
          `/reports/performance/allocation/employee/${selectedUser?.id}`,
          {
            params: {
              start_date: dataForm.start_date,
              end_date: dataForm.end_date || dataForm.start_date,
            },
          },
        )
        .then((response) => {
          // const fullPerformance = response.data.
          const resp = response.data;

          handleExportTable(resp, dataForm.start_date, dataForm.end_date);
        })
        .catch((err) => {
          addToast({
            position: 'top-right',
            isClosable: true,
            status: 'warning',
            title:
              err.response?.data?.error || 'Não foi possível gerar o relatório',
          });
        })
        .finally(() => setLoadingExcel(false));
    },
    [selectedUser, handleExportTable, addToast],
  );

  return (
    <Box>
      <SectionHeader
        title={`Alocação ${department?.name || ''}`}
        pagename="Alocação de equipes"
        loading={!department}
        goBackLink={goLink}
      >
        <ToggleCustom
          defaultIsChecked={toggleAdjustExpectedAllocation}
          toogleAdjust={() =>
            setToggleAdjustExpectedAllocation((state) => !state)
          }
          loading={loading}
          tittle="Ajustar alocação prevista"
          id="adjustExpectedAllocation"
        />
      </SectionHeader>
      <Flex flexDir="column" alignItems={['center', 'start']} mt={-5} mb={3}>
        <Text mb={2} as="b">
          Previsto: {department?.alocacao_prevista_geral}%
        </Text>
        {queryParams.start_date && queryParams.end_date ? (
          <Text textAlign="center" mb={2} as="b">
            Período Analisado:{' '}
            {format(parseISO(queryParams.start_date), 'dd/MM/yyyy')} até{' '}
            {format(parseISO(queryParams.end_date), 'dd/MM/yyyy')}
          </Text>
        ) : (
          ''
        )}

        <Text mb={2} as="b">
          Departamento: {department?.name}
        </Text>
        <Text mb={2} as="b">
          Responsável: {department?.responsible}
        </Text>
      </Flex>
      {loading ? (
        <LoadingAbsolute />
      ) : (
        <>
          {!!teams.length && (
            <Accordion defaultIndex={indexDefaultDepartment} allowToggle>
              {teams.map((team) => {
                return (
                  <AccordionItem key={team.id}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          {team.name}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {team.funcionarios
                      .filter(
                        (filter) =>
                          // filter.status_id !== 5 &&
                          filter.status_id !== 5 ||
                          filter.total_alocacao_percent !== 0 ||
                          filter.total_alocacao_prevista_percent !== 0,
                      )
                      .map((func) => {
                        return (
                          <AccordionPanel
                            key={func.id}
                            pb={4}
                            minH="auto"
                            width="full"
                            background="#f8f8f8"
                            my={3}
                            pt={6}
                            borderLeft="solid 2px transparent"
                            _hover={{ borderLeft: 'solid 2px blue' }}
                          >
                            <Flex m={3}>
                              <Flex
                                width="180px"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Avatar name={func.nome} mr={3} />
                                <Flex
                                  flexDir="column"
                                  alignItems="start"
                                  justifyContent="center"
                                  mr={4}
                                >
                                  <Text ml={[0, 3]}>{func.nome}</Text>

                                  <Box>
                                    {func.status_id !== 1 && (
                                      <BadgeStatusInfo
                                        status={func.status_nome}
                                        statusColor={func.nome_original}
                                        size="md"
                                      />
                                    )}
                                    {func.status_id !== 5 && (
                                      <>
                                        <Link
                                          to={`/planejamento?employee=${func.id}`}
                                        >
                                          <Text fontSize="xs">
                                            <Badge
                                              colorScheme="blue"
                                              cursor="pointer"
                                            >
                                              Planejamento
                                            </Badge>
                                          </Text>
                                        </Link>

                                        <Badge
                                          colorScheme="blue"
                                          cursor="pointer"
                                          onClick={() => {
                                            onOpen();
                                            setSelectedUser(func);
                                          }}
                                        >
                                          <Flex
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Box mr="5px">
                                              <FaTasks />
                                            </Box>
                                            <Box>
                                              <Text fontSize="xs">
                                                Desempenho
                                              </Text>
                                            </Box>
                                          </Flex>
                                        </Badge>
                                      </>
                                    )}
                                  </Box>
                                </Flex>
                              </Flex>
                              <Box width="full">
                                <Text fontSize={['sm', 'md']}>
                                  Previsto:{' '}
                                  {func.total_alocacao_prevista_percent}%
                                </Text>
                                <ProgressCustom
                                  rounded="3px"
                                  color="#090979"
                                  size="lg"
                                  value={func.total_alocacao_prevista_percent}
                                />

                                <Text fontSize={['sm', 'md']} mt={2}>
                                  Realizado: {func.total_alocacao_percent}%
                                </Text>
                                <ProgressCustom
                                  rounded="3px"
                                  color="#00BFFF"
                                  size="lg"
                                  value={func.total_alocacao_percent}
                                />
                              </Box>
                            </Flex>
                            <Flex
                              alignItems="center"
                              mt={3}
                              justifyContent={['center', 'start']}
                            >
                              <Flex width={['350px', 'auto']} flexWrap="wrap">
                                <Flex
                                  height="100px"
                                  flexDir="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  mr={3}
                                  mt="-7px"
                                  border="solid 1px transparent"
                                >
                                  <Text width="90px" fontSize={['sm', 'md']}>
                                    Realizado %
                                  </Text>
                                  <Text width="90px" fontSize={['sm', 'md']}>
                                    Previsto %
                                  </Text>
                                </Flex>
                                <Flex flexWrap="wrap">
                                  {func.alocacao_data.map((alloc) => {
                                    return (
                                      <Tooltip
                                        key={alloc.data_alocacao}
                                        label={
                                          !alloc.alocacao_percent
                                            ? 'Não há alocação realizada para exibir'
                                            : ''
                                        }
                                        placement="top"
                                      >
                                        <Link
                                          to={
                                            alloc.alocacao_percent
                                              ? `/relatorios/alocacao/funcionario/${
                                                  func.id
                                                }?department_id=${
                                                  func.departamento_id
                                                }&select_date=${
                                                  alloc.format_date_allocation
                                                }&start_date=${
                                                  queryParams.start_date
                                                }&end_date=${
                                                  queryParams.end_date
                                                }&adjust=${
                                                  toggleAdjustExpectedAllocation
                                                    ? 1
                                                    : 0
                                                }`
                                              : location.search
                                          }
                                        >
                                          <PercentEmployee
                                            flexDir="column"
                                            alignItems="center"
                                          >
                                            <Flex
                                              flexDir="column"
                                              alignItems="center"
                                              justifyContent="center"
                                              m={1}
                                              mt="15px"
                                              width="60px"
                                              height="60px"
                                            >
                                              <Text fontSize={['sm', 'md']}>
                                                {alloc.alocacao_percent}%
                                              </Text>
                                              <Divider
                                                background="#00000085"
                                                height="0.5px"
                                                width="80%"
                                              />
                                              <Text fontSize={['sm', 'md']}>
                                                {
                                                  alloc.alocacao_prevista_percent
                                                }
                                                %
                                              </Text>
                                            </Flex>
                                            <Box width="70px">
                                              <Text
                                                textAlign="center"
                                                fontSize="12px"
                                              >
                                                {alloc.data_alocacao}
                                              </Text>
                                            </Box>
                                          </PercentEmployee>
                                        </Link>
                                      </Tooltip>
                                    );
                                  })}
                                </Flex>
                              </Flex>
                            </Flex>
                          </AccordionPanel>
                        );
                      })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </>
      )}

      {/* <BarAllocation id={1} nome= status_id alocacao_data /> */}
      <ModalChakra
        title="Período do Relatório"
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={() => formRef.current?.submitForm()}
        isLoading={loadingExcel}
        titleButtonConfirm="Solicitar"
      >
        <Form ref={formRef} onSubmit={handleExportExcel}>
          <Flex
            flexDir={['column', 'row']}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <InputChakra
                name="start_date"
                type="month"
                label="Período inicio"
                width="250px"
                defaultValue={startDate}
                // disabled={loadingExcel}
              />
            </Box>
            <Box>
              <InputChakra
                name="end_date"
                type="month"
                label="Período fim"
                width="250px"
                // disabled={loadingExcel}
              />
            </Box>
          </Flex>
        </Form>
      </ModalChakra>
    </Box>
  );
};

export default AllocationDepartment;
